import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/action-records', {
      params,
    })
    return response
  },
}
