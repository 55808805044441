export default [{
  path: '/activation',
  component: () =>
    import('./views/Index'),
  redirect: { name: 'activation' },
  children: [{
    path: '',
    name: 'activation',
    component: () =>
      import('./views/Activation'),
    meta: {
      resource: 'activation-learn',
    },
  },],
},]
