export default [
  {
    path: '/arena-categories',
    component: () => import('./views/Index'),
    name: 'arena-category',
    redirect: { name: 'arena-category-list' },
    children: [
      {
        path: '',
        name: 'arena-category-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'content',
        },
      },
      {
        path: ':category_id',
        name: 'arena-category-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'content',
        },
      },
    ],
  },
]
