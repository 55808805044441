import { Ability } from '@casl/ability'
import { getUserData, isUserLoggedIn } from '../cookie'
import { initialAbility } from './config'

function getAbility() {
  const user_data = getUserData()
  let existingAbility = []
  if (isUserLoggedIn()) {
    existingAbility = user_data.ability ? user_data.ability.concat(initialAbility) : initialAbility
  }
  return existingAbility
}
const ability = new Ability(getAbility())
export default ability
export function updateAbility() {
  ability.update(getAbility())
}
