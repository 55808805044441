<template>
  <div>
    <b-card>
      <strong class="h6">Heading Text</strong>
      <b-form-input
        v-model="componentData.value"
        name="heading_text"
        placeholder="Enter your heading here"
      />
      <strong class="h6 d-block mt-1">Text Align</strong>
      <div class="d-flex">
        <b-form-radio
          v-model="componentData.meta.text_align"
          :name="'heading-text-align' + keyComponent"
          value="left"
        >Left</b-form-radio>
        <b-form-radio class="ml-1"
          v-model="componentData.meta.text_align"
          :name="'heading-text-align' + keyComponent"
          value="center"
        >Center</b-form-radio>
        <b-form-radio class="ml-1"
          v-model="componentData.meta.text_align"
          :name="'heading-text-align' + keyComponent"
          value="right"
        >Right</b-form-radio>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'HeadingField',
  props: {
    componentData: { type: Object, required: true },
    keyComponent:  { type: [ String, Number ], default: 0 }
  },
  data() {
    return {
    }
  },
}
</script>
