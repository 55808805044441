export default [
  {
    path: '/social-plus-topic',
    component: () => import('./views/Index'),
    name: 'social-plus-topic',
    redirect: { name: 'social-topic-list' },
    children: [
      {
        path: '',
        name: 'social-topic-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'social-plus',
        },
      },
      {
        path: ':id',
        name: 'social-topic-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'social-plus',
        },
      },
    ],
  },
]
