<template>
  <div>
    <span class="d-block text-center mt-1 font-italic">Click into the image to add it!</span>
    <b-row class="p-1 w-100 ml-0">
      <b-col cols="6" v-for="(item) in component_list" :key="item.type">
        <b-card class="cursor-pointer" border @click="pickComponent(item.type)">
          <b-img :src="item.thumbnail" class="w-100" />
          <h6 class="h6 text-center mt-1 mb-0" style="height: 2.7em; line-height: 1.7">{{ item.title }}</h6>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import defaultObjects from './default-field-data'

export default {
  data() {
    return {
      component_list: [
        {
          type: 'heading',
          title: 'Heading',
          thumbnail: require('@/assets/images/ui-components/heading.svg'),
        },
        {
          type: 'richtext',
          title: 'Rich Text',
          thumbnail: require('@/assets/images/ui-components/richtext.svg'),
        },
        {
          type: 'image',
          title: 'Image',
          thumbnail: require('@/assets/images/ui-components/image.svg'),
        },
        {
          type: 'image-text',
          title: 'Image With Text',
          thumbnail: require('@/assets/images/ui-components/image-text.svg'),
        },
        {
          type: 'audio',
          title: 'Audio',
          thumbnail: require('@/assets/images/ui-components/audio.png'),
        },
        {
          type: 'video',
          title: 'Video',
          thumbnail: require('@/assets/images/ui-components/video.png'),
        },
        {
          type: 'tab',
          title: 'Tab',
          thumbnail: require('@/assets/images/ui-components/tab.png'),
        },
        {
          type: 'table-text',
          title: 'Table Text',
          thumbnail: require('@/assets/images/ui-components/table.jpg'),
        },
        {
          type: 'card-text',
          title: 'Card Text',
          thumbnail: require('@/assets/images/ui-components/card.png'),
        },
        {
          type: 'button',
          title: 'Button',
          thumbnail: require('@/assets/images/ui-components/button.png'),
        },
        {
          type: 'text-mesh-pro',
          title: 'TextMesh Pro',
          thumbnail: require('@/assets/images/ui-components/richtext.svg'),
        },
        {
          type: 'text-area',
          title: 'Text Area',
          thumbnail: require('@/assets/images/ui-components/richtext.svg'),
        },
        {
          type: 'multiple-title',
          title: 'Multiple Title',
          thumbnail: require('@/assets/images/ui-components/richtext.svg'),
        },
        {
          type: 'review',
          title: 'Review',
          thumbnail: require('@/assets/images/ui-components/image-text.svg'),
        },
        {
          type: 'image-info1',
          title: 'Image Info 1',
          thumbnail: require('@/assets/images/ui-components/image-text.svg'),
        },
        {
          type: 'image-info2',
          title: 'Image Info 2',
          thumbnail: require('@/assets/images/ui-components/image-text.svg'),
        },
        {
          type: 'image-info3',
          title: 'Image Info 3',
          thumbnail: require('@/assets/images/ui-components/image-text.svg'),
        },
        {
          type: 'discount-option1',
          title: 'Discount Option 1',
          thumbnail: require('@/assets/images/ui-components/richtext.svg'),
        },
        {
          type: 'discount-option2',
          title: 'Discount Option 2',
          thumbnail: require('@/assets/images/ui-components/richtext.svg'),
        },
        {
          type: 'timeup',
          title: 'Time Up',
          thumbnail: require('@/assets/images/ui-components/timeup.png'),
        },
        {
          type: 'list-text',
          title: 'List Text',
          thumbnail: require('@/assets/images/ui-components/richtext.svg'),
        },
        {
          type: 'text-num-text',
          title: 'Text Num Text',
          thumbnail: require('@/assets/images/ui-components/richtext.svg'),
        },
      ],
    }
  },
  methods: {
    getDefaultField(type) {
      let default_object = defaultObjects[type]
      if (!default_object) { return null }
      default_object.id = Date.now()

      return JSON.parse(JSON.stringify(default_object))
    },
    pickComponent(type) {
      this.$emit('picked', this.getDefaultField(type))
    },
  },
}
</script>
