import Cookies from 'js-cookie'

export function setAccessToken(value) {
  Cookies.set('accessToken', value, { path: '/' })
}

export function getAccessToken() {
  return Cookies.getJSON('accessToken')
}

export function setUserData(value) {
  Cookies.set('userData', value, { path: '/' })
}

export function getUserData() {
  return Cookies.getJSON('userData')
}

export const isUserLoggedIn = () => {
  return getAccessToken() && getUserData()
}
