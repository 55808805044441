<template>
  <div v-if="isOpen">
    <b-modal
      centered
      title="Action Detail"
      v-model="isOpen"
      id="modal-lg"
      size="lg"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Url</label>
          </b-col>
          <b-col md="8">
            <b-input :value="getTitle(model.url)" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Method</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.method" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Document id</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.document_id" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Email</label>
          </b-col>
          <b-col md="8">
            <b-input :value="model.user_email" disabled />
          </b-col>
        </b-row>

        <b-row class="pb-2">
          <b-col md="2">
            <label for="">Body</label>
          </b-col>
          <b-col md="8">
            <b-textarea :value="getBody(model.request_data.body)" rows="7" disabled />
          </b-col>
        </b-row>
      </b-card>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isOpen: false,
      isUpdate: false,
    };
  },
  methods: {
    async openDetailModal(action){
      this.model = action;
      this.isOpen = true;
      this.isUpdate = true;
    },

    cancel(){
      this.isOpen = false;
      this.isUpdate = false;
      this.model = {};
    },

    getTitle(url) {
      return url ? url.slice(8) : ''
    },

    getBody(data) {
      if (typeof data === 'object') {
        const keys = Object.keys(data)
        if (keys.length === 1 && keys[0] === 'data') {
          data = JSON.stringify(JSON.parse(data.data))
          return data
        }
      }
      return JSON.stringify(data)
    }
  },
};
</script>
<style lang="scss" scoped>
  label {
    font-size: 16px;
    margin-top: 0.3rem;
    margin-bottom: 0;
  }
</style>
