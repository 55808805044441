<template>
  <b-card>
    <div>
        <strong class="h6">Content Text</strong>
        <b-textarea
        class="editor"
        v-model="componentData.text"
        rows="3"
        />
    </div>
    <div class="mt-1">
        <strong class="h6">End Datetime</strong>
        <datetime-picker
            :config="{ enableTime: true, altFormat: 'd/m/Y, H:i', dateFormat: 'Z', }"
            v-model="componentData.end_time"
        />
    </div>
  </b-card>
</template>

<script>

export default {
  name: 'TimeUp',
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
