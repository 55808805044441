<template>
  <component
    :is="componentName"
    :component-data="componentData"
    :key="index"
    @updated="$emit('updated')"
  />
</template>

<script>
import Word from "./word/WordList.vue";
import DeepListeningVideo from "./DeepListeningVideo/VideoField.vue"
import Listening from "./listening/TopicCard.vue";
import Pronun from './pronun/_components/SentenceList.vue'
import DeepCatchUp from './DeepCatchUp/DeepCatchUp.vue'
import ExpertCatchUp from './ExpertCatchUpAI/ExpertCatchUp.vue'
import DeepRapidResponse from './DeepRapidResponse/DeepRapidResponse.vue'
export default {
  name: "BreakingComponent",
  components: {
    "topic-field-1": Word,
    "topic-field-2": DeepListeningVideo,
    "topic-field-3": Listening,
    "topic-field-4": Pronun,
    "topic-field-5": DeepCatchUp,
    "topic-field-6": ExpertCatchUp,
    "topic-field-7": DeepRapidResponse
  },
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      index: 1
    }
  },
  watch: {
    'componentData.content'() {
      this.index++
    }
  },
  computed: {
    componentName() {
      let name = `topic-field-`+this.componentData.type;
      let localComponents = Object.keys(this.$options.components);
      return localComponents.includes(name) ? name : "empty-field";
    },
  },
};
</script>
