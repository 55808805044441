<template>
  <div>
    <b-form-group label="Title" label-for="titleInput">
      <b-form-input
        id="largeInput"
        v-model="componentData.title"
        size="lg"
        placeholder="Title"
      />
    </b-form-group>
    <b-row>
      <b-col v-if="componentData.table.headers.length > 0" cols="11">
        <b-table-simple bordered hover large caption-top responsive>
          <b-thead style="background-color: #f8f8f8">
            <b-tr>
              <b-td
                v-for="(header, index) in componentData.table.headers"
                :key="index"
              >
                <span v-if="header.key === 'name'" class="text-nowrap">
                  <h5>{{ header.label }}</h5>
                  {{header.sub_label}}
                </span>
                <b-row v-else>
                  <b-col style="margin-top: 8px" cols="10">
                    <h5>{{ header.label }}</h5>
                    {{header.sub_label}}
                  </b-col>
                  <b-col cols="2">
                    <b-button
                      @click="deleteColumn(index)"
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                    >
                      <feather-icon icon="Trash2Icon" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-td>
              <b-td />
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(item, index) in componentData.table.items"
              :key="index"
            >
              <b-td v-for="(header, i) in componentData.table.headers" :key="i">
                <span v-if="header.key === 'name'" class="text-nowrap">
                  {{ item[header.key] }}
                </span>
                <b-form-checkbox
                  v-model="item[header.key]"
                  class="custom-control-success"
                  v-else
                />
              </b-td>
              <b-td class="text-center">
                <b-button
                  @click="editRow(index)"
                  variant="flat-primary"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-button
                  @click="deleteRow(index)"
                  variant="flat-danger"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
      <b-col class="mt-1" cols="1">
        <b-button
          @click="openModal"
          variant="flat-success"
          class="btn-icon rounded-circle"
          size="sm"
        >
          <feather-icon icon="ColumnsIcon" />
        </b-button>
      </b-col>
    </b-row>
    <item-create-modal
      ref="itemCreateModal"
      v-if="componentData.table.headers.length > 0"
      :table="componentData.table"
    />
    <header-create-modal ref="headerCreateModal" :table="componentData.table" />
  </div>
</template>

<script>
import HeaderCreateModal from "./HeaderCreateModal.vue";
import ItemCreateModal from "./ItemCreateModal.vue";
export default {
  name: "TableTextField",
  components: {
    HeaderCreateModal,
    ItemCreateModal,
  },
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {};
  },
  methods: {
    openModal() {
      this.$refs.headerCreateModal.openModal();
    },
    deleteRow(index) {
      this.componentData.table.items.splice(index, 1);
    },
    editRow(index) {
      this.$refs.itemCreateModal.openModal(index);
    },
    deleteColumn(index) {
      let infoHeader = this.componentData.table.headers[index];
      this.componentData.table.items.forEach((item) => {
        delete item[infoHeader.key];
      });
      this.componentData.table.headers.splice(index, 1);
      if (this.componentData.table.headers.length <= 1) {
        this.componentData.table.items = [];
      }
    },
  },
};
</script>
