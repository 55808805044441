<template>
  <div>
    <b-card class="mb-0">
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="componentData.code"
        :style="
          componentData.thumbnail
            ? 'background-image: url(' +
              componentData.thumbnail +
              '); background-size: cover;'
            : ''
        "
        v-html="isPlay ? componentData.code : ''"
        style="min-height: 600px"
      />
      <iframe
        :style="
          componentData.thumbnail
            ? 'background-image: url(' +
              componentData.thumbnail +
              ');background-size: cover;'
            : ''
        "
        v-else-if="componentData.src"
        :src="isPlay ? componentData.src + '?autoplay=true' : ''"
        allowfullscreen="1"
        frameborder="0"
        width="100%"
        height="640"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      />
      <div
        v-else
        class="d-flex align-items-center justify-content-center w-100"
        style="height: 300px; background-color: rgba(34, 41, 47, 0.5)"
      >
        <strong>No video yet. Please set one!</strong>
      </div>
      <b-button
        v-if="!isPlay && (componentData.src || componentData.code)"
        variant="gradient-danger"
        class="btn-icon rounded-circle"
        @click="play"
        style="position: absolute; top: 45%; right: 50%"
      >
        <feather-icon icon="PlayIcon" />
      </b-button>
      <div class="d-flex pt-1">
        <b-button size="sm" variant="gradient-primary" @click="openModal" pill>
          <span class="align-middle">Upload</span>
          <feather-icon icon="UploadIcon" class="ml-50" />
        </b-button>
        <b-button
          size="sm"
          variant="gradient-danger"
          pill
          class="ml-50"
          @click="clear"
        >
          <feather-icon icon="Trash2Icon" class="mr-50" />
          <span class="align-middle">Clear</span>
        </b-button>
      </div>
    </b-card>
    <!-- select 2 demo -->
    <b-modal
      v-model="isModalShow"
      title="Update Data"
      hide-footer
      size="lg"
      modal-class="modal-primary"
    >
      <b-form-group label="Video URL" label-for="video_src">
        <b-form-input
          id="video_src"
          name="video_src"
          placeholder="URL"
          v-model="componentData.src"
        />
      </b-form-group>
      <b-form-group label="Thumbnail" label-for="thumbnail">
        <b-form-input
          id="thumbnail"
          name="thumbnail"
          placeholder="Thumbnail"
          v-model="componentData.thumbnail"
        />
      </b-form-group>
      <b-form-group label="Video title" label-for="video_title">
        <b-form-input
          id="video_title"
          name="video_title"
          placeholder="Video title"
          v-model="componentData.meta.title"
        />
      </b-form-group>
      <b-form-group label="Video alt" label-for="video_alt">
        <b-form-input
          id="video_alt"
          name="video_alt"
          placeholder="Video alt"
          v-model="componentData.meta.alt"
        />
      </b-form-group>
      <b-form-group label="Video code" label-for="video_code">
        <b-form-textarea
          id="textarea-default"
          placeholder="Video code"
          rows="3"
          v-model="componentData.code"
        />
      </b-form-group>
      <b-form-group label="Sub" label-for="sub">
        <language-select
          :language="language"
          @selectedLanguage="selectedLanguage"
        />
        <b-form-input
          id="link"
          name="link"
          class="mt-1"
          placeholder="Link sub"
          v-model="sub.link"
        />
      </b-form-group>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button variant="primary" @click="update">Update</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import defaultObject from "./defaultObject";
import { resetObject } from "@core/utils";
export default {
  name: "VideoField",
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      isModalShow: false,
      editingData: null,
      isPlay: false,
      language: "vi",
      sub: {
        link: null,
      },
    };
  },
  watch: {
    "componentData.thumbnail": function (newValue) {
      this.componentData.thumbnail = newValue.replace(" ", "%20");
    },
  },
  created() {
    this.bindDataSub();
  },
  methods: {
    openModal() {
      this.editingData = { ...this.componentData };

      this.isModalShow = true;
      this.isPlay = false;
    },
    cancel() {
      resetObject(this.componentData, { ...this.editingData });
      this.isModalShow = false;
    },
    update() {
      this.isModalShow = false;
    },
    clear() {
      resetObject(this.componentData, { ...defaultObject });
      this.$emit("updated");
    },
    play() {
      this.isPlay = true;
    },
    selectedLanguage(language) {
      this.language = language;
      this.bindDataSub();
    },
    bindDataSub() {
      if (!this.componentData.subs) {
        this.componentData.subs = [];
      }
      if (this.componentData.thumbnail) {
        this.componentData.thumbnail = this.componentData.thumbnail.replace(
          " ",
          "%20"
        );
      }
      let sub = this.componentData.subs.find(
        (item) => item.language == this.language
      );
      if (!sub) {
        sub = { language: this.language, link: "" };
        this.componentData.subs.push(sub);
      }
      this.sub = sub;
    },
  },
};
</script>
