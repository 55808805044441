
import service from './service'

export default {
  namespaced: true,
  state: {
    languages: [],
  },
  mutations: {
    GET_LANGUAGES(state, languages) {
      state.languages = languages
    }
  },
  actions: {
    async setLanguage({ commit }) {
      let response = await service.getAll();
      if (response.data.data) {
        commit("GET_LANGUAGES", response.data.data);
      }
    }
  },
  getters: {
    language_options(state){
      return state.languages.map((item) => {
        return {
          text: item.code,
          value: item.code,
        };
      });
    }
  }
}
