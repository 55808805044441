<template>
  <div>
    <b-tabs>
        <b-tab
          v-for="(tab, i) in tabs"
          :key="'dyn-tab-' + i"
          :title="tab.name"
        >
          <b-list-group>
            <draggable
              v-model="tab.items"
              class="list-group list-group-flush cursor-move"
              tag="ul"
            >
              <transition-group
                type="transition"
                name="list"
              >
            <b-list-group-item :key="index.toString() + i" v-for="(item, index) in tab.items" class="d-flex justify-content-between align-items-center">

              <b-col>
                <span>
                {{ item.name }}
                </span>
                <span class="mr-1">
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="playAudio(i, index)"
                  >
                    <feather-icon icon="Volume2Icon" size="17" />
                  </b-button>
                </span>
              </b-col>
              <b-col class="text-center">
                  {{ item.spelling }}
              </b-col>
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item @click="openModal('audio', i,index)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteAudio(i, index)">
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </b-list-group-item>
            </transition-group>
    </draggable>
          </b-list-group>
          <b-button
            size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="primary"
            class="mt-1"
            @click="openModal('audio', i, null)"
          >
            Add audio
          </b-button>

          <b-button
            size="sm"
            variant="danger"
            class="float-right mt-2"
            @click="closeTab(i)"
          >
            Delete tab
          </b-button>
        </b-tab>
        <template #tabs-end>
          <b-nav-item
            role="presentation"
            @click.prevent="openModal('tab')"
          >
            <feather-icon class="custom-icon" icon="PlusCircleIcon" />
          </b-nav-item>
        </template>
        <template #empty>
          <div class="text-center text-muted">
            There are no open tabs<br>
            Open a new tab using the <b>+</b> button above.
          </div>
        </template>
    </b-tabs>
    <modal-tab @createTab="createTab" ref="modalTab" />
    <modal-audio @createAudio="createAudio" ref="modalAudio" />
  </div>
</template>

<script>
import ModalTab from './ModalTab.vue'
import ModalAudio from './ModalAudio.vue'
import defaultObject from './defaultObject'
import { resetObject } from '@core/utils'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'

export default {
  name: 'TabField',
  directives: {
    Ripple,
  },
  components: {
    ModalTab,
    ModalAudio,
    draggable,
  },
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      editingData: null,
      tabs: [],
    }
  },
  created() {
    if (this.componentData && this.componentData.tabs && this.componentData.tabs[0] && this.componentData.tabs[0].name) {
      this.tabs = this.componentData.tabs
    }
  },
  methods: {
    closeTab(indexTab) {
      this.tabs.splice(indexTab, 1)
      resetObject(this.componentData, { tabs: this.tabs })
    },
    openModal(type, indexTab = null, indexAudio = null) {
      if (type === 'audio') {
        let infoAudio;
        if(indexAudio != null) {
         infoAudio = this.tabs[indexTab]?.items[indexAudio]
        }
        this.$refs.modalAudio.openModal(indexTab, indexAudio, infoAudio)
      } else {
        this.$refs.modalTab.openModal()
      }
    },
    createTab(name) {
      let convetName = name.trim()
      let findTab = this.tabs.findIndex(item => item?.name === convetName)
      if (findTab < 0) {
        this.tabs.push({ ...defaultObject, name: convetName, items: [] })
      } else {
        this.$store.dispatch('pushErrorNotify', {
          text: `Tab ${convetName} existed!`,
        })
      }
      resetObject(this.componentData, { tabs: this.tabs })
    },
    createAudio(tab, indexTab) {
      this.tabs[indexTab].items.push(tab)
      resetObject(this.componentData, { tabs: this.tabs })
    },
    deleteAudio(indexTab, indexAudio) {
      this.tabs[indexTab].items.splice(indexAudio, 1)
      resetObject(this.componentData, { tabs: this.tabs })
    },
    playAudio(indexTab, indexAudio) {
      let link = this.tabs[indexTab].items[indexAudio].audio.value
      let audio = new Audio(link)
      audio.play()
    },
  },
}
</script>
<style scoped>
.custom-icon {
  color: #3da07b;
  margin-top: 7px;
}
</style>
