export default [
    {
        path: '/native11-speaking-test',
        component: ()=> import('./views/Index.vue'),
        name: 'native11-speaking-test',
        redirect: { name: 'native11-speaking-test-list' },
        children: [
        {
            path: 'levels',
            name: 'native11-speaking-test-list',
            component: () => import('./views/List'),
            meta: {
                resource: 'content',
            },
        },
        {
            path: 'level/:id',
            name: 'native11-speaking-test-detail',
            component: () => import('./views/Detail'),
            meta: {
            resource: 'content',
            },
        },
        ],
    }
]