<template>
  <div>
    <div class="text-center">
      <b-button
        @click="openModal(null)"
        variant="flat-success"
        class="btn-primary"
        size="sm"
      >
        + Add row
      </b-button>
    </div>
    <b-modal
      id="row"
      ok-only
      ok-title="Save"
      centered
      title="Row"
      v-model="isOpen"
      @ok="save"
    >
      <b-form-group label="Name" label-for="name">
        <b-form-input id="name" name="name" placeholder="name" v-model="name" />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    table: { type: Object, required: true },
  },
  data() {
    return {
      isOpen: false,
      name: "",
      editing: null,
    };
  },
  methods: {
    openModal(index) {
      if (index || index == 0) {
        this.editing = index;
        this.name = this.table.items[this.editing].name;
      }
      this.isOpen = true;
    },
    save() {
      if (this.editing || this.editing == 0) {
        this.table.items[this.editing].name = this.name;
      } else {
        let item = {};
        item.name = this.name;
        this.table.headers.forEach((header) => {
          if (header.key != "name") {
            item[header.key] = false;
          } else {
            header.label = "";
          }
        });
        this.table.items.push(item);
      }
      this.name = "";
    },
  },
};
</script>

