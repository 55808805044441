export default [
  {
    path: '/pronun/topics',
    component: () => import('./views/Index'),
    name: 'pronun-topics',
    meta: {
      navActiveLink: 'pronun',
    },
    children: [
      {
        path: ':topic_id',
        name: 'pronun-topic-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'auth',
        },
      },
    ],
  },
]
