<template>
  <b-card>
    <div v-for="(item, index) in model" :key="Math.random()+index" class="border p-50 mb-50">
      <form-generator :schema="schema" :model="item" />
    </div>
  </b-card>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  name: 'config-rate-list',
  setup() {},
  props: {
    model: { type: Array, required: true },
    schema: { type: Array, required: true },
  },
})
</script>
