export default {
  namespaced: true,
  state: {
    dropRateValidation: [],
    slotPriceValidation: [],
  },
  getters: {},
  mutations: {
    ADD_UNVALIDATE_RATE(state, val) {
      let isExisted = state.dropRateValidation.find((x)=>{
        return x == val
      })
      if (!isExisted){
        state.dropRateValidation.push(val)
      }
    },
    REMOVE_UNVALIDATE_RATE(state, val) {
      state.dropRateValidation.splice(state.dropRateValidation.findIndex((x)=>{
        return x == val
      }, 1))
    },
    ADD_SLOT_PRICE(state,val){
      let isExisted = state.slotPriceValidation.find((x)=>{
        return x == val
      })
      if (!isExisted){
        state.slotPriceValidation.push(val)
      }
    },
    REMOVE_SLOT_PRICE(state,val){
      state.slotPriceValidation.splice(state.slotPriceValidation.findIndex((x)=>{
        return x == val
      }, 1))
    }
  },
  actions: {
    
  },
}
