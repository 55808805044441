export default [
  {
    path: '/utests',
    component: () => import('./views/Index'),
    redirect: { name: 'utest.run' },
    children: [
      {
        path: '',
        name: 'utest.run',
        component: () => import('./views/Run'),
        meta: {
        },
      },
    ],
  },
]
