export default [
    {
        path: '/native-report',
        component: ()=> import('./views/Index.vue'),
        name: 'native11-report',
        children: [
            {
                path: 'teacher-rate-student',
                component: ()=> import('./views/TeacherRatingStudent.vue'),
                name: 'native11-student-rating',
            },
            {
                path: 'student-rate-teacher',
                component: ()=> import('./views/StudentRatingTeacher.vue'),
                name: 'native11-teacher-rating',
            },
            {
                path: 'room-info',
                component: ()=> import('./views/ClassInfomation.vue'),
                name: 'native11-room-info-report',
            },
            {
                path: 'teacher-report',
                component: ()=> import('./views/TeacherReport.vue'),
                name: 'native11-teacher-report',
            },
        ]
    }
    
]