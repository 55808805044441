export default [
  {
    path: '/mission',
    component: () => import('./views/Index'),
    name: 'random-ggt-reward-rate',
    redirect: { name: 'random-ggt-reward-rate-list' },
    children: [
      {
        path: 'random-ggt-reward-rate',
        name: 'random-ggt-reward-rate-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'management',
        },
      },
    ],
},
]
