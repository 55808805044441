<template>
  <b-card>
    <div class="mb-1">
        <strong class="h6">Start Text</strong>
        <b-input
        class="editor"
        v-model="componentData.start_text"
        />
    </div>
    <div class="mb-1">
        <strong class="h6">Number Text</strong>
        <b-input
        class="editor"
        v-model="componentData.num_text"
        />
    </div>
    <div>
        <strong class="h6">End Text</strong>
        <b-input
        class="editor"
        v-model="componentData.end_text"
        />
    </div>
  </b-card>
</template>

<script>

export default {
  name: 'TextNumText',
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
