export default [
  {
    path: '/ai-chats',
    component: () => import('./views/Index'),
    redirect: { name: 'ai-chats' },
    children: [
      {
        path: '',
        name: 'ai-chats',
        component: () => import('./views/List'),
        meta: {
        },
      },
    ],
  },
]
