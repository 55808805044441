<template>
  <div>
    <h2>Video</h2>
    <ui-component :componentData="componentData.content.video"></ui-component>
  </div>
</template>
<script>
export default {
  props:{
    componentData: {type: Object, required: true}
  }
}
</script>