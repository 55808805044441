export default [
  {
    path: '/native11',
    component: () => import('./views/Index'),
    name: 'native11',
    redirect: { name: 'native11-teacher-list' },
    children: [
      {
        path: 'teachers',
        name: 'native11-teacher-list',
        component: () => import('./views/TeacherList'),
        meta: {
          resource: 'social-plus',
        },
      },
    ],
  },
]
