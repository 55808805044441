<template>
  <b-badge :variant="`light-${matchedOption.variant || 'primary'}`">
    {{ matchedOption.text }}
  </b-badge>
</template>
<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'option-tag',
  setup() {},
  props: {
    options: { type: Array, default: () => ([]) },
    key_value: { default: null },
  },
  computed: {
    matchedOption() {
      let matched_option = this.options.find(option => option.value == this.key_value);
      return matched_option || { text: 'unknown', variant: 'secondary' };
    },
  },
})
</script>
