export default {
  type: 'translatable-text',
  meta: {
    default_lang: 'vi'
  },
  value: '',
  translations: [
    {
      language: 'vi',
      value: ''
    }
  ]
}
