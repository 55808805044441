<template>
  <b-button :variant="variant" :size="size"
    @click="showActionTable"
  >
    <b-spinner v-if="isLoading" small />
    <template v-else>
      <slot>
        <feather-icon icon="ClockIcon" />
      </slot>
    </template>
  </b-button>
</template>

<script>
import ActionRecordService from '../../../../modules/action_record/service'
export default {
  name: 'HistoryButton',
  props: {
    variant: { type: String, default: 'primary' },
    size: { type: String, default: null },
    isLoading: { type: Boolean, default: false },
  },
  methods: {
    showActionTable() {
      this.$emit('openActionTableModal')
    },
  },
}
</script>
