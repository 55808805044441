<template>
  <div>
    <b-modal
      id="header"
      ok-only
      ok-title="Save"
      centered
      title="Header"
      v-model="isOpen"
      @ok="save"
    >
      <b-form-group label="Label" label-for="label">
        <b-form-input
          id="label"
          name="label"
          placeholder="Name"
          v-model="header.label"
        />
      </b-form-group>

      <b-form-group label="Sub label" label-for="sub_label">
        <b-form-input
          id="sub_label"
          name="sub_label"
          placeholder="Sub Name"
          v-model="header.sub_label"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    table: { type: Object, required: true },
  },
  data() {
    return {
      isOpen: false,
      header: {},
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    save() {
      let label = this.header.label.toLowerCase();
      this.header.key = label.trim().split(" ").join("_");
      if (this.table.headers.length == 0) {
        this.table.headers.push({
          key: "name",
          label: "",
        });
      }
      let checkHeader = this.table.headers.find(
        (item) => item.key == this.header.key
      );
      if (!checkHeader) {
        this.table.headers.push(this.header);
        if (this.table.items.length > 0) {
          this.table.items.forEach((item) => {
            item[this.header.key] = false;
          });
        }
      }
      this.header = {};
    },
  },
};
</script>

