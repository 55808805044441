<template>
  <div><strong>Component not found: {{ `${componentData.type}-field` }}</strong></div>
</template>

<script>
export default {
  props: {
    componentData: { type: Object, required: true },
  },
}
</script>
