export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('./views/Error404'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-not-authorized',
    name: 'error-not-authorized',
    component: () => import('./views/NotAuthorized.vue'),
    meta: {
      layout: 'full',
    },
  },
]
