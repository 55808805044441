export default [
    {
      path: '/breaking-quiz',
      component: () => import('./views/Index'),
      name: 'breaking-quiz',
      redirect: { name: 'breaking-quiz-list' },
      children: [
        {
          path: '',
          name: 'breaking-quiz-list',
          component: () => import('./views/List'),
          meta: {
            resource: 'auth',
          },
        },
        {
          path: ':id',
          name: 'breaking-quiz-detail',
          component: () => import('./views/Detail'),
          meta: {
            resource: 'auth',
          },
        },
      ],
    },
  ]
  