export default [
    {
      path: '/goga-exam-level',
      component: () => import('./views/Index'),
      name: 'goga-exam-level',
      redirect: { name: 'goga-exam-level-list' },
      children: [
        {
          path: '',
          name: 'goga-exam-level-list',
          component: () => import('./views/List'),
          meta: {
            resource: 'content',
          },
        },
        {
            path: ':id',
            name: 'goga-exam-level-detail',
            component: () => import('./views/Detail'),
            meta: {
              resource: 'content',
            },
          },
      ],
    },
  ]
  