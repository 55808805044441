<template>
  <div>
    <b-card class="mb-0">
      <b-card-img v-if="componentData.value" :src="componentData.value" alt="Image" top />
      <div v-else
        class="d-flex align-items-center justify-content-center w-100 blank_image"
      >
        <strong>No image yet. Please set one!</strong>
      </div>
      <div class="d-flex pt-1">
        <b-button size="sm" variant="gradient-primary" pill @click="openUpdateForm">
          <span class="align-middle">Upload</span>
          <feather-icon
            icon="UploadIcon"
            class="ml-50"
          />
        </b-button>
        <b-button v-if="!!componentData.value"
          size="sm" variant="gradient-danger" pill class="ml-50"
          @click="clearImage"
        >
          <feather-icon
            icon="Trash2Icon"
            class="mr-50"
          />
          <span class="align-middle">Clear</span>
        </b-button>
      </div>
      <div v-if="componentData.meta.redirect_link" class="mt-1">
        <strong>Redirect Link:</strong> <a :href="componentData.meta.redirect_link">{{ componentData.meta.redirect_link }}</a>
      </div>
    </b-card>
    <b-modal
      v-model="isModalShow" v-if="editingData"
      title="Update Data"
      hide-footer
      size="lg" modal-class="modal-primary"
    >
      <b-form-group
        label="Image URL"
        label-for="image_src"
      >
        <b-form-input
          v-model="editingData.value"
          id="image_src"
          name="image_src"
          placeholder="URL"
        />
      </b-form-group>

      <b-form-group
        label="Image URL Local"
        label-for="image_src_local"
      >
        <b-form-input
          v-model="editingData.value_local"
          id="image_src_local"
          name="image_src_local"
          placeholder="URL Local"
        />
      </b-form-group>

      <b-form-group
        label="Image Alt"
        label-for="image_alt"
      >
        <b-form-input
          v-model="editingData.meta.alt"
          id="image_alt"
          name="image_alt"
        />
      </b-form-group>

      <!-- <b-form-group
        label="Redirect Link"
        label-for="image_link"
      >
        <b-form-input
          v-model="editingData.meta.redirect_link"
          id="image_link"
        />
      </b-form-group> -->

      <hr>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button variant="primary" @click="update">Update</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { resetObject } from '@core/utils'
import defaultObject from './defaultObject'

export default {
  name: 'ImageField',
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      isModalShow: false,
      editingData: null,
    }
  },
  created() {
  },
  methods: {
    clearImage() {
      resetObject(this.componentData, { ...defaultObject })
      this.$emit('updated')
    },
    cancel() {
      this.isModalShow = false
    },
    update() {
      resetObject(this.componentData, this.editingData)
      this.isModalShow = false
      this.$emit('updated')
    },
    openUpdateForm() {
      this.editingData = { ...this.componentData }
      this.isModalShow = true
    },
  },
}
</script>
<style scoped>
  .blank_image{
    height: 200px;
    background-color: rgba(34, 41, 47, 0.5)
  }
</style>
