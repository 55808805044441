export default [
  {
    path: '/bot',
    component: () => import('./views/Index'),
    name: 'bot',
    redirect: { name: 'bot-list' },
    children: [
      {
        path: 'list',
        name: 'bot-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'management-bot',
        },
      },
    ],
  },
]
