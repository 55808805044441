<template>
  <div>
    <b-card class="mb-0">
      <div v-for="(id) in componentData.items.length" :key="id">
        <b-row class="mt-1">
          <b-col cols="4">
            <strong class="h6">Image</strong>
            <image-field :component-data="componentData.items[id - 1].image" class="border" />
          </b-col>
          <b-col cols="7">
            <strong class="h6">Name</strong>
            <b-form-input
              v-model="componentData.items[id - 1].name"
              name="name"
              placeholder="Michael Jackson"
            />
            <strong class="h6">Level</strong>
            <b-form-input
              v-model="componentData.items[id - 1].level"
              name="level"
              placeholder="Level: Beginner"
            />
            <strong class="h6">Study time</strong>
            <b-form-input
              v-model="componentData.items[id - 1].study_time"
              name="study_time"
              placeholder="Study time: one week"
            />
            <strong class="h6">Review content</strong>
            <b-form-textarea
              v-model="componentData.items[id - 1].review_content"
              name="review_content"
              placeholder="Sahara words..."
            />
          </b-col>
          <b-col cols="1">
            <b-button
              @click="confirmDelete(id - 1)"
              variant="flat-danger"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="text-center mt-2">
        <b-button @click="addItem()" variant="flat-success" class="btn-primary" size="sm">+ Add item</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import ImageField from '../image-field/ImageField.vue'
import defaultObject from './defaultObject'

export default {
  name: 'ReviewField',
  components: {
    ImageField,
  },
  props: {
    componentData: { type: Object, required: true },
    keyComponent: { type: [String, Number], default: 0 }
  },
  data() {
    return {
    }
  },
  methods: {
    addItem() {
      this.componentData.items.push(JSON.parse(JSON.stringify(defaultObject)))
    },

    confirmDelete(index) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteItem(index);
          }
        });
    },

    deleteItem(index) {
      this.componentData.items = [...this.componentData.items.slice(0, index), ...this.componentData.items.slice(index + 1)]
    }
  }
}
</script>
