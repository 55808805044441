export default [
  {
    path: '/is-tag-management',
    component: () => import('./views/Index'),
    name: 'is-tag-management',
    redirect: { name: 'is-tag-list' },
    children: [
      {
        path: '',
        name: 'is-tag-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'auth',
        },
      },
    ],
  },
]
