export default [
  {
    path: '/goga-rank-topic',
    component: () => import('./views/Index'),
    name: 'goga-rank-topic',
    redirect: { name: 'goga-rank-topic-list' },
    children: [
      {
        path: 'topics',
        name: 'goga-rank-topic-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'content',
        },
      },
      {
        path: 'topic/:id',
        name: 'goga-rank-topic-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'content',
        },
      },
    ],
  },
]
