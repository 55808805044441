<template>
  <div>
    <b-form-select v-model="selectedField" :options="options" />
  </div>
</template>

<script>
export default {
  name: "SelectInput",
  props: {
    options: { type: Array, default: () => [] },
    fieldName: { type: String, default: '' },
  },
  data() {
    return {
      selectedField: '',
    };
  },
  watch: {
    selectedField: function () {
      this.$emit(`selected${this.capitalizeFirstLetter(this.fieldName)}`, this.selectedField);
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string[0].toUpperCase() + string.slice(1);
    },
  },
};
</script>
