export default [
  {
    path: '/earn-luckytickets',
    name: 'earn-luckyticket',
    component: () => import('./views/Index'),
    redirect: { name: 'luckyticket-events' },
    children: [
      {
        path: '',
        name: 'luckyticket-events',
        component: () => import('./views/EventList'),
        meta: {
          resource: 'goga-earn',
        },
      },
    ],
  },
]
