<template>
  <b-card>
    <b-textarea
      class="editor"
      v-model="componentData.value"
    />
  </b-card>
</template>

<script>

export default {
  name: 'TextArea',
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
