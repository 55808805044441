export default [
  {
    path: '/marnet-employees',
    component: () => import('./views/Index'),
    name: 'marnet-employee',
    redirect: { name: 'marnet-employee-records' },
    children: [
      {
        path: 'records',
        name: 'marnet-employee-records',
        component: () => import('./views/Records'),
        meta: {
          resource: 'auth',
        },
      },
    ],
  },
]
