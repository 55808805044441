export default [
  {
    path: '/breaking/topics',
    component: () => import('./views/Index'),
    name: 'breaking-topics',
    meta: {
      navActiveLink: 'breaking',
    },
    children: [
      {
        path: ':topic_id',
        name: 'breaking-topic-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'auth',
          navActiveLink: 'breaking-lesson'
        },
      },
    ],
  },
]
