export default [
  {
    path: '/notifications',
    component: () => import('./views/Index'),
    name: 'notification',
    redirect: { name: 'noti-group-list' },
    children: [
      {
        path: 'groups',
        name: 'noti-group-list',
        component: () => import('./views/GroupList'),
        meta: {
          resource: 'goga-earn',
        },
      },
      {
        path: 'groups/:id',
        name: 'noti-group-detail',
        component: () => import('./views/GroupDetail'),
        meta: {
          resource: 'goga-earn',
        },
      },
      {
        path: 'groups/:id/triggers/:trigger_id',
        name: 'noti-trigger-detail',
        component: () => import('./views/TriggerDetail'),
        meta: {
          resource: 'goga-earn',
        },
      },
    ],
  },
]
