export default [
  {
    path: '/mails',
    component: () => import('./views/Index'),
    name: 'mail',
    redirect: { name: 'mail-detail' },
    children: [
      {
        path: '/',
        name: 'mail-detail',
        component: () => import('./views/List'),
        meta: {
          resource: 'auth',
        },
      }
    ],
  },
]
