export default [
  {
    path: '/post',
    component: () => import('./views/Index'),
    name: 'post',
    redirect: { name: 'post-list' },
    children: [
      {
        path: '',
        name: 'post-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'content',
        },
      },
      {
        path: 'detail/:post_id',
        name: 'post-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'content',
        },
      },
      {
        path: 'create',
        name: 'post-create',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'content',
        },
      },
    ],
  },
]
