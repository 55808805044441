import ability from './ability'

export const canNavigate = (to) => {
  return to.matched.every((routeRecord) => {
    if (!routeRecord.meta.resource) { return true }
    const can = ability.can(routeRecord.meta.action || 'read', routeRecord.meta.resource)
    return can
  })
}

export const _ = undefined
