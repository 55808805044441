<template>
  <div>
    <b-row class="mb-1">
      <b-col class="mt-1" cols="4">
        <h2 class="h2">Words</h2>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        class="col-lg-3 col-md-6 col-12"
        v-for="(word,index) in words"
        :key="index"
      >
        <word-card
          :word="word"
          :index="index"
          @confirmDelete="confirmDelete"
          @updateWord="openUpdateModal"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center mt-1" cols="12">
        <b-button variant="primary" @click="openCreateModal">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span>Add Word</span>
        </b-button>
      </b-col>
    </b-row>
    <word-create-modal
      @addWord="addWord"
      @updateWord="updateWord"
      ref="modalWord"
    />
  </div>
</template>

<script>
import WordCard from "./WordCard.vue";
import WordCreateModal from "./WordCreateModal";
import { resetObject } from "@core/utils";

export default {
  name: "word-field",
  components: {
    WordCard,
    WordCreateModal,
  },
  props: {
    componentData: {type: Object, required: true}
  },
  data() {
    return {
      currentWord: { type: Object },
      words: this.componentData.content.words,
    };
  },
  methods: {
    addWord(word) {
      this.componentData.content.words.push(word)
    },
    updateWord(updateData){
      this.componentData.content.words[updateData.index] = updateData.word
    },
    openCreateModal(){
      this.$refs.modalWord.openCreateModal()
    },
    openUpdateModal(updateData){
      console.log(updateData)
      this.$refs.modalWord.openUpdateModal(updateData.word, updateData.index)
    },
    confirmDelete(index) {
      this.$bvModal
      .msgBoxConfirm("Are you sure ?", {
        title: "Please Confirm",
        size: "md",
        okVariant: "danger",
        okTitle: "Yes",
        cancelTitle: "No",
        cancelVariant: "outline-secondary",
        hideHeaderClose: true,
        centered: true,
      })
      .then((value) => {
        if (value === true) {
          this.deleteWord(index);
        }
      });
    },
    deleteWord(index){
      this.componentData.content.words.splice(index,1)
    }
  },
};
</script>
