<template>
  <multiselect v-bind="$attrs" v-on="{ ...$listeners, input: update}" :placeholder="placeholder" :show-labels="false"
    :multiple="multiple" :searchable="searchable" show-no-results label="text" track-by="value"
    :id="id" :name="id"
    :options="options"
    :value="transformValue"
    :allowEmpty="allow_empty"
    @input="update"
  >
    <template v-slot:caret="{ toggle }">
      <div @mousedown.prevent.stop="toggle()" class="multiselect__select"
        style="position: absolute; right: -6px; z-index: 100;"
      ></div>
      <b-button v-if="!isEmpty && allow_empty" variant="flat-secondary" class="btn-icon" size="sm"
        style="position: absolute; right: 20px; top: 6px; z-index: 100;"
        @click="clear"
      >
        <feather-icon class="text-danger" icon="XIcon" />
      </b-button>
    </template>
    <template slot="singleLabel" slot-scope="props">
      <b-row>
        <b-img class="d-inline-block ml-50" style="width: 1.3em;" rounded v-if="props.option.img" :src="props.option.img" />
        <span class="ml-50">{{ props.option.text }}</span>
      </b-row>
    </template>
    <template slot="option" slot-scope="props">
      <b-row>
        <img class="d-inline-block ml-50" style="width: 1.3em;" v-if="props.option.img" :src="props.option.img">
        <span class="ml-50">{{ props.option.text }}</span>
      </b-row>
    </template>
  </multiselect>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'
export default {
  name: 'multi-select',
  components: {
    Multiselect
  },
  props: {
    multiple: { type: Boolean, default: true },
    searchable: { type: Boolean, default: true },
    id: { type: String, required: true },
    placeholder: { type: String, default: 'Option' },
    options: { type: Array, default: [] },
    label: { type: String, default: 'text' },
    value: {},
    allow_empty: { type: Boolean, default: true}
  },
  data() {
    return {
    }
  },
  computed: {
    transformValue() {
      let value;
      if(this.multiple) {
        value = this.options.filter(item => this.value.includes(item.value))
      } else {
        value = this.options.find(item => this.value === item.value);
      }
      return value;
    },
    isEmpty() {
      if(this.multiple) {
        return !this.transformValue.length;
      } else {
        return !this.transformValue;
      }
    }
  },
  methods: {
    update(new_value) {
      let data = null;
      if(this.multiple) {
        data = new_value.map(item => item.value);
      } else {
        data = new_value ? new_value.value : null;
      }
      this.$emit('input', data);
    },
    clear() {
      this.$emit('input', this.multiple ? [] : null);
    },
  }
}
</script>
