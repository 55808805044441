export default [
  {
    path: '/earn-fomula-config',
    component: () => import('./views/Index'),
    name: 'earn-fomula-config',
    redirect: { name: 'earn-fomula-config-list' },
    children: [
      {
        path: '/earn-fomula-config',
        name: 'earn-fomula-config-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'goga-earn',
        },
      },
      {
        path: '/earn-fomula-config/detail/:config_key',
        name: 'earn-fomula-config-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'goga-earn',
        },
      },
    ],
  },
]
