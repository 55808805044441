export default [
  {
    path: '/drop-rate-config',
    component: () => import('./views/Index'),
    name: 'drop-rate-config',
    meta: {
      resource: 'auth',
    },
  },
]
