export default [
  {
    path: '/goga-rank',
    component: () => import('./views/Index'),
    name: 'goga-rank-reward',
    redirect: { name: 'reward-list' },
    children: [
      {
        path: 'reward',
        name: 'reward-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'management',
        },
      },
    ],
},
]
