export default {
  name: null,
  items: [
    {
      name: null,
      audio: {
        value: null,
        meta: {
          title: null,
          captions: [],
        },
      },
      spelling: null,
    },
  ],
}
