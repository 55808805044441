<template>
  <b-card>
    <quill-editor
      class="editor"
      ref="myTextEditor"
      v-model="componentData.value"
      :options="editorOption"
    />
    <div v-if="componentData.meta" class="pt-1 d-flex">
      <div class="mr-3">
        <strong class="h6">Border display</strong>
        <div class="d-flex">
          <b-form-checkbox
            class="custom-control-primary"
            name="is_publish_checkbox"
            switch
            v-model="componentData.meta.border.display"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckCircleIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XCircleIcon" />
            </span>
          </b-form-checkbox>
          <strong>{{ componentData.meta.border.display ? "Yes" : "No" }}</strong>
        </div>
      </div>
      <div>
        <strong class="h6">Background display</strong>
        <div class="d-flex">
          <b-form-checkbox
            class="custom-control-primary"
            name="is_publish_checkbox"
            switch
            v-model="componentData.meta.background.display"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckCircleIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XCircleIcon" />
            </span>
          </b-form-checkbox>
          <strong>{{ componentData.meta.background.display ? "Yes" : "No" }}</strong>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import hljs from 'highlight.js'

export default {
  name: 'RichtextField',
  components: {
    quillEditor,
  },
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            // ['blockquote', 'code-block'],
            // [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            // [{ script: 'sub' }, { script: 'super' }],
            // [{ indent: '-1' }, { indent: '+1' }],
            // [{ direction: 'rtl' }],
            // [{ size: ['small', false, 'large', 'huge'] }],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // [{ font: [] }],
            [{ color: [] }],
            [{ align: [] }],
            ['clean'],
            // ['link', 'image', 'video'],
          ],
          syntax: {
            highlight: text => hljs.highlightAuto(text).value,
          },
        },
      },
    }
  },
  computed: {
    editor() {
      return this.$refs.myTextEditor.quill
    },
  },
  created(){
    if (!this.componentData.meta){
      this.componentData["meta"] = {
        border: {
          display: false,
        },
        background: {
          display: false,
        }
      }
    }
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
</style>
