export default [
  {
    path: '/ai-test',
    component: () => import('./views/Index'),
    children: [
      {
        path: 'pronun-zh',
        name: 'ai-test.pronun_zh',
        component: () => import('./views/PronunciationZh.vue'),
        meta: {
        },
      },
      {
        path: 'speech-to-text-zh',
        name: 'ai-test.s2t_zh',
        component: () => import('./views/S2TZh.vue'),
        meta: {
        },
      },
    ],
  },
]
