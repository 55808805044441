<template>
  <div v-if="isOpen">
    <b-modal
      centered
      title="Action Table"
      v-model="isOpen"
      id="modal-lg"
      size="xl"
      hide-footer
      @hide="cancel"
    >
      <b-card class="mt-1">
        <action-table :display-results="actionRecords" :is-loading="isLoading" />
        <b-pagination
          class="mt-auto"
          pills
          size="lg"
          align="center"
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="itemsPerPage"
        />
      </b-card>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ActionTable from "./ActionTable.vue";
import service from "./../../service";
export default {
  components: {
    ActionTable,
  },
  data() {
    return {
      isLoading: false,
      isOpen: false,
      isUpdate: false,
      actionRecords: [],
      documentId: "",
      currentPage: 1,
      totalItems: 0,
      itemsPerPage: 30,
    };
  },
  watch: {
    documentId() {
      this.getResults();
    },
    currentPage() {
      this.getResults();
    },
  },
  methods: {
    async getResults() {
      this.isLoading = true;
      let response = await service.getList({
        page: this.currentPage,
        limit: this.itemsPerPage,
        search_key: this.documentId,
      });
      let results = [];
      if (response.data.type === "DATA") {
        results = response.data.data.list;
        this.actionRecords = results;
        this.totalItems = response.data.data.total;
      }
      this.isLoading = false;
    },

    async openActionTableModal(documentId) {
      this.documentId = documentId;
      this.isOpen = true;
      this.isUpdate = true;
    },

    cancel() {
      this.isOpen = false;
      this.isUpdate = false;
    },
  },
};
</script>
<style lang="scss" scoped>
label {
  font-size: 16px;
  margin-top: 0.3rem;
  margin-bottom: 0;
}
</style>
