export default [
  {
    path: '/goga-rank',
    component: () => import('./views/Index'),
    name: 'goga-rank-virtual-user',
    redirect: { name: 'virtual-user-list' },
    children: [
      {
        path: 'virtual-user',
        name: 'virtual-user-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'management-virtual-user',
        },
      },
    ],
  },
]
