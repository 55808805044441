export default [
  {
    path: '/config',
    component: () => import('./views/Index'),
    name: '',
    redirect: { name: 'stage-config' },
    children: [
      {
        path: 'stage-config',
        name: 'stage-config',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'management',
        },
      },
    ],
  },
]
