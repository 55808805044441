export default [
    {
      path: '/native11-payment',
      component: () => import('./views/Index'),
      name: 'native11-payment',
      redirect: { name: 'native11-payment-list' },
      children: [
        {
          path: '',
          name: 'native11-payment-list',
          component: () => import('./views/List'),
          meta: {
            resource: 'social-plus',
          },
        },
        {
            path: ':id',
            name: 'native11-payment-detail',
            component: () => import('./views/Detail'),
            meta: {
              resource: 'auth',
            },
        },
      ],
    },
  ]
  