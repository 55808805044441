import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  pushInfoNotify(store, { title, text, icon } = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._vm.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: title || 'Message',
        icon: icon || 'AlertCircleIcon',
        variant: 'info',
        text,
      },
    })
  },

  pushSuccessNotify(store, { title, text, icon } = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._vm.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: title || 'Success!',
        icon: icon || 'CheckIcon',
        variant: 'success',
        text,
      },
    })
  },
  // pushErrorNotify(store, { title, text, icon } = {}) {
  //   // eslint-disable-next-line no-underscore-dangle
  //   this._vm.$toast({
  //     component: ToastificationContent,
  //     position: 'top-right',
  //     props: {
  //       title: title || 'Error!',
  //       icon: icon || 'AlertTriangleIcon',
  //       variant: 'danger',
  //       text,
  //     },
  //   })
  // },
  pushErrorNotify(store, { title, text, icon } = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._vm.$swal({
      title: title || 'Error!',
      icon: icon || 'error',
      text,
      // footer: '<a href="">Why do I have this issue?</a>'
    })
  },
  pushWarningNotify(store, { title, text, icon } = {}) {
    // eslint-disable-next-line no-underscore-dangle
    this._vm.$toast({
      component: ToastificationContent,
      position: 'top-right',
      props: {
        title: title || 'Warning!',
        icon: icon || 'AlertTriangleIcon',
        variant: 'warning',
        text,
      },
    })
  },

}
