export default [
  {
    path: "/grammar-card",
    component: () => import("./views/Index"),
    name: "grammar-card",
    redirect: { name: "grammar-card-list" },
    children: [
      {
        path: "",
        name: "grammar-card-list",
        component: () => import("./views/List"),
        meta: {
          resource: "content"
        }
      },
      {
        path: ":id",
        name: "grammar-card-detail",
        component: () => import("./views/Detail"),
        meta: {
          resource: "content"
        }
      }
    ]
  }
];
