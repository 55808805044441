<template>
  <div>
    <b-card class="mb-0">
      <strong class="h6">Content Text</strong>
      <richtext-field :component-data="componentData.text" class="border" />
      <b-row class="mt-1">
        <b-col>
          <strong class="h6">Image</strong>
          <image-field :component-data="componentData.image" class="border" />
        </b-col>
        <b-col>
          <strong class="h6">Image Position</strong>
          <b-form-radio class="mt-1"
            v-model="componentData.meta.image_position"
            :name="'image-position' + keyComponent"
            value="right"
          >To the right of content text</b-form-radio>
          <b-form-radio class="mt-1"
            v-model="componentData.meta.image_position"
            :name="'image-position' + keyComponent"
            value="left"
          >To the left of content text</b-form-radio>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import ImageField from '../image-field/ImageField.vue'
import RichtextField from '../richtext-field/Field.vue'

export default {
  name: 'ImageTextField',
  components: {
    ImageField,
    RichtextField,
  },
  props: {
    componentData: { type: Object, required: true },
    keyComponent:  { type: [ String, Number ], default: 0 }
  },
  data() {
    return {
    }
  },
}
</script>
