export default {
  heading: {
    type: 'heading',
    value: null,
    meta: {
      text_align: 'left',
    },
  },
  richtext: {
    type: 'richtext',
    value: null,
    meta: {
      border: {
        display: false,
      },
      background: {
        display: false,
      }
    },
  },
  image: {
    type: 'image',
    value: null,
    meta: {
      url: null,
      alt: null,
    },
  },
  audio: {
    type: 'audio',
    value: null,
    meta: {
      title: null,
    },
  },
  'image-text': {
    type: 'image-text',
    image: {
      type: 'image',
      value: null,
      meta: {
        url: null,
        alt: null,
      },
    },
    text: {
      type: 'richtext',
      value: null,
      meta: {
        border: {
          display: false,
        },
        background: {
          display: false,
        }
      },
    },
    meta: {
      image_position: 'left',
    },
  },
  video: {
    type: 'video',
    src: null,
    code: null,
    thumbnail: null,
    subs: [],
    meta: {
      title: null,
      alt: null,
    },
  },
  tab: {
    type: 'tab',
    tabs: [
      {
        name: null,
        items: [
          {
            name: null,
            audio: {
              value: null,
              meta: {
                title: null,
                captions: [],
              },
            },
            spelling: null,
          },
        ],
      },
    ],
  },
  'table-text': {
    type: 'table-text',
    title: null,
    table: {
      headers: [],
      items: []
    }
  },
  'card-text': {
    type: 'card-text',
    items: []
  },
  'button': {
    type: 'button',
    title: null,
    description: null,
    action_type: null,
    action_params: [],
    meta: {}
  },
  'text-mesh-pro': {
    type: 'text-mesh-pro',
    value: null,
  },
  'text-area': {
    type: 'text-area',
    value: null,
  },
  'multiple-title': {
    type: 'multiple-title',
    titles: [''],
  },
  'review': {
    type: 'review',
    items: [
      {
        image: {
          type: "image",
          value: null,
          meta: {
            url: null,
            alt: null
          }
        },
        name: null,
        level: null,
        study_time: null,
        review_content: null
      }
    ],
  },
  'image-info1': {
    type: 'image-info1',
    image: {
      type: "image",
      value: null,
      meta: {
        url: null,
        alt: null
      }
    },
    text: {
      type: "text-key",
      text_key: null,
      text_number: null,
      meta: {
        text_key_position: "right"
      }
    },
    textarea: {
      type: "text-area",
      value: null
    },
    meta: {
      image_position: "top"
    }
  },
  'image-info2': {
    type: 'image-info2',
    image: {
      type: "image",
      value: null,
      meta: {
        url: null,
        alt: null
      }
    },
    text: {
      type: "text-key",
      text_key: null,
      text_number: null,
      meta: {
        text_key_position: "top"
      }
    },
    textarea: {
      type: "text-area",
      value: null
    },
    meta: {
      image_position: "left"
    }
  },
  'image-info3': {
    type: 'image-info3',
    image: {
      type: "image",
      value: null,
      meta: {
        url: null,
        alt: null
      }
    },
    text: {
      type: "text-key",
      text_key: null,
      text_number: null,
      meta: {
        text_key_position: "right"
      }
    },
    textarea: {
      type: "text-area",
      value: null
    },
    meta: {
      image_position: "bottom"
    }
  },
  'discount-option1': {
    type: 'discount-option1',
    price_sale: null,
    price: null,
    info_sale: null,
    store_id: null,
    auto_renew: false,
  },
  'discount-option2': {
    type: 'discount-option2',
    text_voucher: null,
    subtext: null,
    discount_voucher: null,
  },
  timeup:{
    type: "timeup",
    text: null,
    end_time: null
  },
  'list-text': {
    type: "list-text",
    texts: [""]
  },
  'text-num-text':{
    type: 'text-num-text',
    start_text: null,
    num_text: null,
    end_text: null
  }
}
