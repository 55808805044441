export default [
  {
    path: '/transfer-record',
    component: () => import('./views/Index'),
    name: 'transfer-record',
    redirect: { name: 'transfer-record-list' },
    children: [
      {
        path: '',
        name: 'transfer-record-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'goga-earn',
        },
      }
    ],
  },
]
