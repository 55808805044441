<template>
  <div>
    <div class="d-flex mb-1 justify-content-end">
      <b-button v-if="!is_editing" class="btn-icon" variant="flat-secondary" @click="is_editing = true">
        <feather-icon icon="EditIcon" />
      </b-button>
      <b-button v-else class="mr-auto btn-icon" variant="relief-success" @click="doneEditing">
        <feather-icon icon="CheckIcon" />
      </b-button>
      <div class="mr-50 ml-auto">
        <label :for="`file_upload-${id}`" class="btn btn-relief-info mb-0 btn-icon"
          v-b-tooltip.hover title="Import Data"
        >
          <feather-icon icon="UploadIcon" />
        </label>
        <input @change="importData" :id="`file_upload-${id}`" type="file">
      </div>
      <b-button class="btn-icon" variant="relief-info" @click="exportData"
        v-b-tooltip.hover title="Export Data"
      >
        <feather-icon icon="DownloadIcon" />
      </b-button>
    </div>
    <vue-good-table :id="id"
      :columns="fields"
      :rows="rows"
      :sort-options="{
        enabled: false,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="is_editing && !props.column.disabled">
          <b-input type="number" v-model="rows[props.row.originalIndex][props.column.field]" />
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table'
import sheet from '@core/utils/sheet'

export default {
  name: 'data-table',
  components: {
    VueGoodTable,
  },
  // setup() {},
  props: {
    rows: { type: Array, required: true },
    fields: { type: Array, required: true },
    id: { type: String, required: true },
  },
  data() {
    return {
      is_editing: false
    }
  },
  methods: {
    exportData(){
      let table = document.getElementById(this.id)
      sheet.exportTableElem(table, this.id)
    },
    async importData(e) {
      let file = e.target.files ? e.target.files[0] : null;
      if(!file) { return }
      let header = this.fields.map(item => item.field)
      let rows = await sheet.importSheet(file, header)
      this.$emit('importFile', rows);
    },
    doneEditing() {
      this.is_editing = false;
      this.$emit('edited');
    },
  }

}
</script>

