<template>
  <div>
    <b-card class="mb-0">
      <strong class="h6">Sale price</strong>
      <b-form-input
        v-model="componentData.price_sale"
        class="mb-1"
        name="price_sale"
        placeholder="899.000đ/năm"
      />
      <strong class="h6">Price</strong>
      <b-form-input
        v-model="componentData.price"
        class="mb-1"
        name="price"
        placeholder="999.000đ"
      />
      <strong class="h6">Price per month</strong>
      <b-form-input
        v-model="componentData.info_sale"
        class="mb-1"
        name="info_sale"
        placeholder="74.916đ/tháng"
      />
      <strong class="h6">Store ID</strong>
      <b-form-input v-model="componentData.store_id" id="store_id" placeholder="Store ID" />
      <b-form-group label="Auto Renew">
        <div class="d-flex">
          <b-form-checkbox
            class="custom-control-primary"
            name="is_free_checkbox"
            switch
            v-model="componentData.auto_renew"
          />
          <strong>{{ componentData.auto_renew ? "Yes" : "No" }}</strong>
        </div>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'DiscountOption1Field',
  props: {
    componentData: { type: Object, required: true },
    keyComponent: { type: [String, Number], default: 0 }
  },
  data() {
    return {
    }
  },
}
</script>
