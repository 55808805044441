<template>
  <component
    :is="componentName"
    :key-component="keyComponent"
    :component-data="componentData"
    :is-input-field="isInputField"
    @updated="$emit('updated')"
  />
</template>

<script>
import ImageField from "./image-field/ImageField.vue";
import AudioField from "./audio-field/AudioField.vue";
import HeadingField from "./heading-field/Field.vue";
import RichtextField from "./richtext-field/Field.vue";
import ImageTextField from "./image-text-field/Field.vue";
import EmptyField from "./EmptyField.vue";
import VideoField from "./video-field/Field.vue";
import TabField from "./tab-field/Field.vue";
import TableTextField from "./table-text-field/Field.vue";
import CardTextField from "./card-text-field/Field.vue";
import TranslatableTextField from "./translatable-text-field/Field.vue";
import TranslatableRichTextField from "./translatable-richtext-field/Field.vue";
import TranslatableComponentsField from "./translatable-components-field/Field.vue";
import ButtonField from './button-field/Field.vue'
import TextMeshPro from './text-mesh-pro/TextMeshPro.vue'
import TextArea from './text-area/TextArea.vue'
import MultipleTitleField from './multiple-title-field/Field.vue'
import ReviewField from './review-field/Field.vue'
import ImageInfo1Field from './image-info1-field/Field.vue'
import ImageInfo2Field from './image-info2-field/Field.vue'
import ImageInfo3Field from './image-info3-field/Field.vue'
import DiscountOption1Field from './discount-option1-field/Field.vue'
import DiscountOption2Field from './discount-option2-field/Field.vue'
import TimeUpField from './timeup-field/TimeUp.vue'
import ListTextField from './list-text-field/ListText.vue'
import TextNumText from './text-num-text-field/TextNumText.vue'
export default {
  name: "UiComponent",
  components: {
    "image-field": ImageField,
    "audio-field": AudioField,
    "heading-field": HeadingField,
    "richtext-field": RichtextField,
    "image-text-field": ImageTextField,
    "empty-field": EmptyField,
    "video-field": VideoField,
    "tab-field": TabField,
    "table-text-field": TableTextField,
    "card-text-field": CardTextField,
    "translatable-text-field": TranslatableTextField,
    "translatable-richtext-field": TranslatableRichTextField,
    "translatable-components-field": TranslatableComponentsField,
    "button-field": ButtonField,
    "text-mesh-pro-field": TextMeshPro,
    "text-area-field": TextArea,
    "multiple-title-field": MultipleTitleField,
    "review-field": ReviewField,
    "image-info1-field": ImageInfo1Field,
    "image-info2-field": ImageInfo2Field,
    "image-info3-field": ImageInfo3Field,
    "discount-option1-field": DiscountOption1Field,
    "discount-option2-field": DiscountOption2Field,
    "timeup-field": TimeUpField,
    "list-text-field": ListTextField,
    "text-num-text-field": TextNumText,
  },
  props: {
    componentData: { type: Object, required: true },
    keyComponent: { type: [String, Number], default: 0 },
    isInputField: { type: Boolean, default: false },
  },
  computed: {
    componentName() {
      let name = `${this.componentData.type}-field`;
      let localComponents = Object.keys(this.$options.components);
      return localComponents.includes(name) ? name : "empty-field";
    },
  },
};
</script>
