<template>
  <b-button :variant="variant" :size="size"
    @click="confirmDelete"
  >
    <b-spinner v-if="isLoading" small />
    <template v-else>
      <slot>
        <feather-icon icon="Trash2Icon" />
      </slot>
    </template>
  </b-button>
</template>

<script>
export default {
  name: 'DeleteButton',
  props: {
    message: { type: String, default: 'Are you sure ?' },
    variant: { type: String, default: 'danger' },
    size: { type: String, default: null },
    isLoading: { type: Boolean, default: false },
  },
  methods: {
    confirmDelete() {
      this.$bvModal
        .msgBoxConfirm(this.message, {
          title: 'Please Confirm',
          size: 'md',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.emitDelete()
          }
        })
    },
    emitDelete() {
      this.$emit('delete')
    },
  },
}
</script>
