<template>
  <div>{{ value }}</div>
</template>

<script>
export default {
  name: "RenderTranslatableText",
  props: {
    componentData: { type: Object, required: true },
  },
  computed: {
    value: function () {
      let translation = this.componentData?.translations?.find(
        (item) => item.language == this.language
      );
      return translation?.value || "";
    },
    language: function () {
      return this.$store.getters["auth/language"];
    },
  },
};
</script>
