<template>
  <b-row class="content-header">
    <!-- Content Left -->
    <b-col
      class="content-header-left mb-2"
      cols="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col>
          <h2 class="content-header-title float-left pr-1 mb-0">
            {{ title }}
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="item in items"
                :key="item.text"
                :active="item.active"
                :to="item.to"
              >
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>

  </b-row>
</template>

<script>

export default {
  name: 'PageBreadcrumb',
  props: {
    items: { type: Array, default() { return [] } },
    title: { type: String, default: null },
  },
}
</script>
