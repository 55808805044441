<template>
  <b-row>
    <!-- cols -->
    <b-col v-for="(col, index) in schema" :key="`${index}_${col.cols}`" :cols="col.cols" :style="col.style">
      <div v-if="col.displayCond ? col.displayCond(model) : true" :style="col.style">

        <!-- fields -->
        <div v-for="(field) in col.fields" :key="`fields${field.field}${field.subField || ''}`"
          :style="{ width: field.width || '100%' }" :class="field.class">
          <b-form-group v-if="field.displayCond ? field.displayCond(model) : true"
            :state="field_states[field.field].state" :invalid-feedback="field_states[field.field].invalid_feedback"
            :description="getFieldDescription(field)">
            <template v-slot:label v-if="!!field.label">
              {{ field.label }} <span v-if="field.validate && field.validate.required" class="text-danger">*</span>
            </template>

            <template v-if="field.input_type === 'image'">
              <component :is="'image-lean'" :id="uniqueId(`form_input-${field.field}-`)" class="border"
                :disabled="field.disabled" :field="field" :model="model" />
            </template>
            <template v-else-if="field.input_type === 'time'">
              <datetime-picker
                :config="{ enableTime: true, noCalendar: true, time_24hr: true, altFormat: 'H:i', dateFormat: 'H:i' }"
                v-model="model[field.field]" />
            </template>
            <template v-else-if="field.input_type === 'datetime'">
              <datetime-picker :config="{ enableTime: true, altFormat: 'd/m/Y, H:i:S', dateFormat: 'Z' }"
                v-model="model[field.field]" />
            </template>
            <template v-else-if="field.input_type === 'datetime_range'">
              <datetime-picker :config="{ enableTime: true, altFormat: 'd/m/Y, H:i:S', dateFormat: 'Z', mode: 'range' }"
                v-model="model[field.field]" />
            </template>
            <template v-else-if="field.input_type === 'date_range'">
              <datetime-picker v-model="model[field.field]" :config="{ mode: 'range' }" />
            </template>
            <template v-else-if="field.input_type === 'date'">
              <datetime-picker v-model="model[field.field]" />
            </template>
            <template v-else-if="field.input_type === 'multiselect' || field.input_type === 'select'">
              <multi-select :multiple="field.input_type === 'multiselect' ? true : false"
                :id="uniqueId(`form_input-${field.field}-`)" :disabled="field.disabled" :options="field.options"
                :allow_empty="field.allow_empty === false ? field.allow_empty : true" v-model="model[field.field]" />
            </template>
            <template v-else-if="field.input_type === 'ui-component'">
              <ui-component class="border" :component-data="model[field.field]" />
            </template>
            <template v-else-if="field.input_type === 'textarea'">
              <b-form-textarea :placeholder="field.label" v-model="model[field.field]" rows="3" max-rows="18" />
            </template>
            <template v-else-if="field.input_type === 'switch'">
              <b-form-checkbox :checked="true" class="custom-control-primary" switch v-model="model[field.field]"
                :name="`form_input-${field.field}`"></b-form-checkbox>
            </template>
            <template v-else-if="field.input_type === 'checkbox'">
              <b-form-checkbox :checked="true" class="custom-control-primary" v-model="model[field.field]"
                :name="`form_input-${field.field}`"></b-form-checkbox>
            </template>
            <template v-else-if="field.input_type === 'language'">
              <language-select :language="model[field.field]" :disabled="field.disabled"
                @selectedLanguage="(lang) => { model[field.field] = lang }" />
            </template>
            <template v-else-if="field.subField">
              <b-form-input :id="uniqueId(`form_input-${field.field}-${field.subField}`)"
                :name="`form_input-${field.field}`"
                :type="input_types[field.input_type] !== undefined ? field.input_type : 'text'"
                :disabled="field.disabled" :state="field_states[field.field].state"
                v-model="model[field.field][field.subField]" @blur="debounceValidateField(field)" />
            </template>
            <template v-else-if="field.input_type === 'slot'">
              <slot :name="'slot_' + field.field" :slot-data="model[field.field]"></slot>
            </template>
            <b-form-input v-else :id="uniqueId(`form_input-${field.field}-`)" :name="`form_input-${field.field}`"
              :type="input_types[field.input_type] !== undefined ? field.input_type : 'text'" :disabled="field.disabled"
              :state="field_states[field.field].state" v-model="model[field.field]"
              @blur="debounceValidateField(field)" />
          </b-form-group>
        </div>
        <!-- fields end -->
        <slot :name="col.name"></slot>
      </div>
    </b-col>
    <!-- cols end -->
  </b-row>
</template>

<script>
import ImageLean from './_fields/image-lean/View'
import _ from 'lodash'
import MultipleTitle from '@core/layouts/components/input-fields/multiple-title-field/Field.vue'
import LanguageSelect from '@/modules/language/views/_components/LanguageSelect.vue'
const validate_des_props = ['min', 'max'];

const input_types = {
  text: '',
  phone: '+84...',
  number: 1,
  email: 'your@gmail.com',
};

export default {
  name: 'form-generator',
  components: {
    ImageLean,
    MultipleTitle,
    LanguageSelect,
  },
  // setup() {},
  props: {
    model: { type: Object, required: true },
    schema: { type: Array, default: [] },
  },
  data() {
    return {
      input_types,
      field_states: {},
    }
  },
  computed: {
    state() {
    },
  },
  created() {
    this.schema.forEach(col => {
      col.fields.forEach(field => {
        this.field_states[field.field] = { state: null, invalid_feedback: null, valid_feedback: null };
        if (!this.model[field.field] && field.default_value) {
          this.model[field.field] = field.default_value
        }
      })
    });
  },
  methods: {
    uniqueId(prefix) {
      return _.uniqueId(prefix);
    },
    getFieldDescription(field) {
      if (!field.validate) { return null }
      let des_list = [];
      for (const [key, value] of Object.entries(field.validate)) {
        if (validate_des_props.includes(key)) {
          if (!des_list.length) { des_list.push(field.validate.type || field.input_type) }
          let des_text = value === true ? `, ${key}` : `, ${key}: ${value}`;
          des_list.push(des_text);
        }
      }
      return des_list.join('');
    },
    validateField(field) {
      let state = { state: true, invalid_feedback: null, valid_feedback: null };
      if (!field.validate) { return state }
      let value = field.subField ? this.model[field.field][field.subField] : this.model[field.field];

      if (field.validate.required && (value === undefined || value === null || value === '')) {
        state.state = false;
        state.invalid_feedback = `required field`;
        this.$forceUpdate();
      } else if (field.validate.type === 'number') {
        value = Number(value);
        if (field.validate.min !== undefined) {
          if (value < field.validate.min) { value = field.validate.min }
        }
        if (field.validate.max !== undefined) {
          if (value > field.validate.max) { value = field.validate.max }
        }
      } else if (field.validate.type === 'string') {
        if (value && field.validate.trim) {
          value = value.trim();
        }
        if (value && field.validate.lowercase) {
          value = value.toLowerCase();
        }
        if (value && field.validate.to_snake) {
          value = value.trim().toLowerCase().replaceAll(' ', '_');
        }
      }
      if (field.subField) {
        this.model[field.field][field.subField] = value;
      }
      else {
        this.model[field.field] = value;
      }
      this.field_states[field.field] = state;
      return state;
    },
    debounceValidateField(field) {
      this.validateField(field);
      // _.debounce(() => {
      //   this.validateField(field);
      // }, 300)()
    },
    validate() {
      let form_state = true;
      this.schema.forEach(col => {
        col.fields.forEach(field => {
          let { state } = this.validateField(field);
          if (!state) { form_state = false }
        })
      });
      return form_state;
    },
  }

}
</script>
