<template>
  <div>
    <b-card class="mb-0">
      <b-row class="mt-1">
        <b-col>
          <strong class="h6">Image</strong>
          <image-field :component-data="componentData.image" class="border" />
        </b-col>
        <b-col>
          <strong class="h6">Text key</strong>
          <b-form-input
            v-model="componentData.text.text_key"
            class="mb-1"
            name="text_key"
            placeholder="Perfect"
          />
          <strong class="h6">Text number</strong>
          <b-form-input
            v-model="componentData.text.text_number"
            class="mb-1"
            name="text_number"
            placeholder="300%"
          />
          <strong class="h6">Description</strong>
          <text-area :component-data="componentData.textarea" class="mb-1"></text-area>
          <div>
            <b>Image position</b>
            : {{componentData.meta.image_position}}
          </div>
          <div>
            <b>Text key position</b>
            : {{componentData.text.meta.text_key_position}}
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import ImageField from '../image-field/ImageField.vue'
import TextArea from '../text-area/TextArea.vue'

export default {
  name: 'ImageInfo2Field',
  components: {
    ImageField,
    TextArea,
  },
  props: {
    componentData: { type: Object, required: true },
    keyComponent: { type: [String, Number], default: 0 }
  },
  data() {
    return {
    }
  },
}
</script>
