<template>
  <div>
    <b-button variant="gradient-success" @click="isModalShow=true" size="sm">
      <feather-icon icon="PlusIcon" class="mr-50" />
      <span class="align-middle">{{ buttonText }}</span>
    </b-button>
    <b-modal v-if="item"
      :title="title" hide-footer size="lg" modal-class="modal-primary"
      :no-close-on-backdrop="false"
      v-model="isModalShow"
    >
      <form-generator :model="item" :schema="schema" ref="data_form" />
      <div class="d-flex justify-content-between my-2">
        <b-button variant="secondary" @click="cancel">
          <feather-icon icon="XIcon" class="mr-50" />
          <span class="align-middle">Cancel</span>
        </b-button>
        <b-button v-if="!isLoading" type="submit" variant="success" @click="create">
          <feather-icon icon="CheckIcon" class="mr-50" />
          <span class="align-middle">Create</span>
        </b-button>
        <b-button v-else class="btn-icon mr-50" variant="success">
          <b-spinner small />
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'data-create-popup',
  components:{
  },
  props: {
    'createDataFn': { type: Function },
    defaultData: { type: Object, default: () => { return {}; } },
    schema: { type: Array, required: true },
    buttonText: { type: String, default: 'Create' },
    title: { type: String, default: 'Create Data' },
  },
  data(){
    return{
      isLoading: false,
      isModalShow: false,
      item: JSON.parse(JSON.stringify(this.defaultData)),
    }
  },
  watch: {
    defaultData: {
      handler(newVal){
        this.item = newVal;
      },
      deep: true
    }
  },
  methods: {
    async create() {
      let is_valid = this.$refs.data_form.validate();
      if(!is_valid) { return }

      if(!this.createDataFn) { console.log('createDataFn notfound'); return }

      this.isLoading = true;
      let new_item = await this.createDataFn({...this.item}).catch(err => {
        this.isLoading = false;
      });
      this.isLoading = false;
      if (new_item) {
        this.$emit("create", new_item);
        this.cancel();
      }
    },
    open(default_data = null) {
      if (default_data) {
        this.item = JSON.parse(JSON.stringify(default_data));
      }
      this.isModalShow = true;
    },
    cancel(){
      this.isModalShow = false;
      this.item = JSON.parse(JSON.stringify(this.defaultData));
    },
  }
}
</script>
