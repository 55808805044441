<template>
  <div>
    <b-table
      :items="displayResults"
      :fields="fields"
      :busy="isLoading"
      responsive=""
      small
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #cell(stt)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(url)="data">
        {{ getTitle(data.item.url) }}
      </template>

      <template #cell(createdAt)="data">
        {{ data.item.createdAt | local_time_string }}
      </template>

      <template #cell(action)="data">
        <b-button
          variant="gradient-warning"
          style="margin-right: 5px"
          class="btn-icon"
          @click="openDetailModal(data.item)"
        >
          <feather-icon icon="EyeIcon" />
        </b-button>
      </template>
    </b-table>
    <action-form-modal ref="modalActionForm" />
  </div>
</template>

<script>
import ActionFormModal from "./ActionFormModal.vue";
export default {
  components: {
    ActionFormModal,
  },
  props: {
    displayResults: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: [
        "STT",
        "url",
        "method",
        "document_id",
        "user_email",
        "createdAt",
        "action",
      ],
    };
  },
  methods: {
    getTitle(url) {
      return url ? url.slice(8) : "";
    },
    openDetailModal(updateData) {
      this.$refs.modalActionForm.openDetailModal(updateData);
    },
  },
};
</script>

<style>
</style>