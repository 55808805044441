export default [
  {
    path: '/earn-product',
    component: () => import('./views/Index'),
    name: 'earn-product',
    redirect: { name: 'earn-product-list' },
    children: [
      {
        path: '',
        name: 'earn-product-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'goga-earn',
        },
      },
      {
        path: 'detail/:product_id',
        name: 'earn-product-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'goga-earn',
        },
      },
    ],
  },
]
