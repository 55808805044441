<template>
  <div>
    <b-row>
      <b-col
        class="mt-2"
        cols="4"
        v-for="(item, index) in componentData.items"
        :key="index"
      >
        <div
          :style="
            'background-color:' +
            (item.default ? 'rgb(128 77 227);' : 'rgb(189 161 255);') +
            'border-radius: 10px'
          "
        >
          <b-card
            :style="
              'background-color:' +
              (item.default ? 'rgb(128 77 227);' : 'rgb(189 161 255);') +
              'border: 0'
            "
          >
            <div style="position: absolute; right: 45%; top: -4px">
              <b-badge
                v-for="(tag, key) in item.tags"
                :key="key"
                pill
                variant="warning"
              >
                {{ tag }}
              </b-badge>
            </div>
            <div class="text-center">
              <span
                :class="[
                  'text-truncate',
                  'd-block',
                  'w-100',
                  item.default ? 'default-color' : 'custom-color',
                ]"
                style="font-size: 1.8rem; font-weight: 400"
              >
                {{ item.name }}
              </span>

              <span
                :class="[
                  'text-truncate',
                  'd-block',
                  'w-100',
                  item.default ? 'default-color' : 'custom-color',
                ]"
                style="font-size: 1.5rem; font-weight: bold"
                >{{ item.price }}
              </span>
              <span
                :class="[
                  'text-truncate',
                  'd-block',
                  'w-100',
                  item.default ? 'default-color' : 'custom-color',
                ]"
                style="font-size: 1rem"
                >{{ item.time }}
              </span>
            </div>
            <div style="position: absolute; right: -20px; top: -4px">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editItem(index)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteItem(index)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-card>
          <b-card
            class="text-center"
            :style="
              'background-color:' +
              (item.default ? 'rgb(204 95 229);' : '#362865;') +
              'border-radius: 5px'
            "
          >
            <span
              :class="[
                'text-truncate',
                'd-block',
                'w-100',
                item.default ? 'default-color' : 'footer-text-color',
              ]"
              style="font-size: 1.6rem; font-weight: bold"
              >{{ item.info_sale }}
            </span>
          </b-card>
        </div>
      </b-col>
    </b-row>
    <div class="text-center mt-2">
      <b-button
        @click="openModal"
        variant="flat-success"
        class="btn-primary"
        size="sm"
      >
        + Add
      </b-button>
    </div>
    <b-modal
      id="content"
      ok-only
      ok-title="Save"
      centered
      title="Content"
      v-model="isOpen"
      @ok="save"
    >
      <b-form-group label="Tag" label-for="tag">
        <b-form-tags
          v-model="content.tags"
          input-id="tags-basic"
          class="mb-2"
        />
      </b-form-group>
      <b-form-group label="Name" label-for="name">
        <b-form-input
          id="name"
          name="name"
          placeholder="Name"
          v-model="content.name"
        />
      </b-form-group>
      <b-form-group label="Price" label-for="price">
        <b-form-input
          id="price"
          name="price"
          placeholder="Price"
          v-model="content.price"
        />
      </b-form-group>
      <b-form-group label="Time" label-for="time">
        <b-form-input
          id="time"
          name="time"
          placeholder="Time"
          v-model="content.time"
        />
      </b-form-group>

      <b-form-group label="Info sale" label-for="info_sale">
        <b-form-input
          id="info_sale"
          name="info_sale"
          placeholder="Info sale"
          v-model="content.info_sale"
        />
      </b-form-group>
      <b-form-checkbox v-model="content.default" class="custom-control-primary">
        Default
      </b-form-checkbox>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "CardTextField",
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      content: {},
      isOpen: false,
      indexUpdate: null,
    };
  },
  methods: {
    openModal() {
      this.isOpen = true;
    },
    deleteItem(index) {
      this.componentData.items.splice(index, 1);
    },
    editItem(index) {
      this.content = this.componentData.items[index];
      this.indexUpdate = index;
      this.openModal();
    },
    save() {
      if (this.content.default) {
        this.componentData.items.forEach((item, index) => {
          if (this.indexUpdate !== index) {
            item.default = false;
          }
        });
      }
      if (this.indexUpdate || this.indexUpdate == 0) {
        this.componentData.items[this.indexUpdate] = this.content;
        this.indexUpdate = null;
      } else {
        this.componentData.items.push(this.content);
      }

      this.content = {};
    },
  },
};
</script>
<style scoped>
.default-color {
  color: white;
}
.custom-color {
  color: black;
}
.footer-text-color {
  color: #c15ddf;
}
.text-body {
  color: #f6f6f6 !important;
}
</style>
