export default {
  src: null,
  code: null,
  thumbnail: null,
  subs: [],
  meta: {
    title: null,
    alt: null,
  },
}
