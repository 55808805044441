<template>
  <div>
    <b-card class="text-center">
      <b-button :variant="getVariant" size="lg" style="min-width: 120px" @click="cancel">{{ componentData.title || 'Button' }}</b-button>
      <b-button
        variant="flat-warning"
        class="btn-icon ml-2"
        @click="openModal()"
      >
        <feather-icon icon="EditIcon" />
      </b-button>
    </b-card>
    <b-modal
      v-model="isModalShow"
      title="Update Data"
      hide-footer
      size="lg"
      modal-class="modal-primary"
      @close="cancel"
      @hide="onHide"
    >
      <b-form-group label="Title" label-for="title">
        <b-form-input
          id="title"
          name="title"
          placeholder="Title"
          v-model="componentData.title"
        />
      </b-form-group>
      <b-form-group label="Description" label-for="description">
        <b-form-input
          id="description"
          name="description"
          placeholder="Description"
          v-model="componentData.description"
        />
      </b-form-group>
      <b-form-group label="Action type">
        <b-form-select v-model="componentData.action_type" :options="action_type_options"/>
      </b-form-group>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button variant="primary" @click="update">Update</b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { resetObject } from "@core/utils";
export default {
  name: 'ButtonField',
  props: {
    componentData: { type: Object, required: true },
    keyComponent:  { type: [ String, Number ], default: 0 }
  },
  data() {
    return {
      action_type_options: [
        {text: "Đăng ký", value: "REGISTER", variant: "success"},
        {text: "Mua", value: "PURCHASE", variant: "primary"},
        {text: "Chờ", value: "WAIT", variant: "warning"}
      ],
      editingData: null,
      isModalShow: false
    }
  },

  computed: {
    getVariant() {
      const option = this.action_type_options.find(op => op.value === this.componentData.action_type)
      return option && option.variant ? option.variant : 'primary'
    }
  },
  
  methods: {
    openModal() {
      this.editingData = { ...this.componentData };

      this.isModalShow = true;
    },

    cancel() {
      resetObject(this.componentData, { ...this.editingData });
      this.isModalShow = false;
    },

    update() {
      this.isModalShow = false;
    },

    onHide(event) {
      if (event.trigger === 'backdrop') {
        this.cancel()
      }
    }
  }
}
</script>