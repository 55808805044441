<template>
  <div>
    <b-card class="mb-0">
      <div class="ml-50 mb-50" v-if="componentData.meta.title"><strong>{{ componentData.meta.title }}</strong></div>
      <div class="d-flex align-items-center">
        <audio v-if="componentData.value && !isModalShow" controls>
          <source :src="componentData.value">
          Your browser does not support the audio element.
        </audio>
        <b-button class="ml-50" variant="gradient-primary" pill @click="openUpdateForm" size="sm">
          <!-- <span class="align-middle">Upload</span> -->
          <feather-icon
            icon="UploadIcon"
          />
        </b-button>
        <b-button v-if="componentData.value"
          variant="gradient-danger" pill class="ml-50" size="sm"
          @click="clear"
        >
          <feather-icon
            icon="Trash2Icon"
          />
          <!-- <span>Clear</span> -->
        </b-button>

      </div>
    </b-card>
    <b-modal
      v-if="editingData"
      v-model="isModalShow"
      title="Update Data"
      hide-footer
      size="lg" modal-class="modal-primary"
    >
      <b-form-group
        label="Audio URL"
        label-for="audio_src"
      >
        <b-form-input
          v-model="editingData.value"
          id="audio_src"
          name="audio_src"
          placeholder="URL"
        />
      </b-form-group>

      <b-form-group
        label="Audio Title"
        label-for="audio_title"
      >
        <b-form-input
          v-model="editingData.meta.title"
          id="audio_title"
          name="audio_title"
        />
      </b-form-group>
      <b-form-group
        label="Audio Duration"
        label-for="audio_duration"
      >
        <b-form-input
          v-model="editingData.meta.duration"
          id="audio_duration"
          name="audio_duration"
        />
      </b-form-group>
      <hr>
      <div class="d-flex justify-content-between my-2">
        <b-button variant="danger" @click="cancel">Cancel</b-button>
        <b-button variant="primary" @click="update">Update</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { resetObject } from '@core/utils'
import defaultObject from './defaultObject'

export default {
  name: 'AudioField',
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      isModalShow: false,
      editingData: null,
    }
  },
  created() {
  },
  methods: {
    clear() {
      resetObject(this.componentData, { ...defaultObject })
      this.$emit('updated')
    },
    cancel() {
      this.isModalShow = false
    },
    update() {
      resetObject(this.componentData, this.editingData)
      this.isModalShow = false
      this.$emit('updated')
    },
    openUpdateForm() {
      this.editingData = { ...this.componentData }
      this.isModalShow = true
    },
  },
}
</script>
