export default [
  {
    path: '/course-lesson',
    component: () => import('./views/Index'),
    name: 'course-lesson',
    redirect: { name: 'course-lesson-list' },
    children: [
      {
        path: '/course/:courseId/lesson',
        component: () => import('./views/List'),
        name: 'course-lesson-list',
        children: [
          
        ]
      },
      {
        path: '/course/:courseId/lesson/:id',
        name: 'course-lesson-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'auth',
        },
      },
    ],
  },
]
