<template>
  <div class="pt-3">
    <section class="my-3">
      <h2 class="h2">Sentences</h2>
      <div class="mt-1">
        <app-collapse type="shadow" class="mt-2 border p-1">
          <draggable v-model="componentData.content.sentences" class="list-group list-group-flush" tag="ul"
            handle=".sentence-grab-button"
          >
            <transition-group type="transition" name="list">
              <app-collapse-item v-for="(sentence, sentenceIndex) in componentData.content.sentences" :key="'a'+sentenceIndex"
                class="question-item"
                :title="`Sentence ${sentenceIndex + 1}`" :is-visible="sentenceIndex === componentData.content.length - 1"
              >
                <template slot="header">
                  <div class="d-flex justify-content-between align-items-center flex-grow-1 w-100">
                    <div class="d-flex align-items-center w-100 sentence-grab-button" style="cursor: grab;">
                      <feather-icon icon="TargetIcon" />
                      <span class="lead collapse-title ml-50 text-truncate d-block w-100" style="max-width: 90%">{{ sentence.content || `Sentence id: ${sentence.id}` }}</span>
                    </div>
                    <div style="position: absolute; right: 30px; ">
                      <b-button variant="flat-danger" class="btn-icon rounded-circle"
                        @click.stop="removeSentence(sentenceIndex)"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>
                  </div>
                </template>
                <sentence-card :sentence-data="sentence" />
              </app-collapse-item>
            </transition-group>
          </draggable>

          <div v-if="!componentData.content.sentences.length" class="text-center"><span>No sentence, create them!</span></div>
        </app-collapse>
        <sentence-create-form class="text-center mt-1"
          @created="addSentence"
        />
      </div>
    </section>

  </div>
</template>

<script>
import SentenceCreateForm from './SentenceCreateForm.vue'
import SentenceCard from './SentenceCard.vue'
import draggable from 'vuedraggable'

export default {
  name:"pronun-field",
  components: {
    SentenceCreateForm,
    SentenceCard,
    draggable,
  },
  props:{
    componentData: {type: Object, required: true}
  },
  data() {
    return {
      isUpdating: false,
      isDeleting: false,
    }
  },
  computed: {
    topicId() {
      return this.$route.params.topic_id
    },
    // breadcrumbItems() {
    //   let items = [
    //     {
    //       text: 'Pronun Lessons',
    //       to: { name: 'pronun-lesson-list' },
    //     },
    //     {
    //       text: `Lesson: ${this.lesson.title || this.lesson._id}`,
    //       to: { name: 'pronun-lesson-detail', params: { lesson_id: this.lesson._id } },
    //     },
    //     {
    //       text: `Step: ${this.topic.step_key || 'NaN'}`,
    //       to: { name: 'pronun-lesson-detail', params: { lesson_id: this.lesson._id } },
    //     },
    //   ]
    //   items.push({ text: `Pronun Topic:  ${this.topic.title || this.topic._id}`, active: true })
    //   return items
    // },
  },
  created() {
    // this.getTopic()
  },
  methods: {
    async addSentence(sentence) {
      this.componentData.content.sentences.push(sentence)
    },
    async removeSentence(sentenceIndex) {
      this.componentData.content.sentences.splice(sentenceIndex, 1)
    },
  },
}
</script>

<style scoped>
.page-item {
  transition: ease 0.6s
}
</style>
