export default [
  {
    path: '/earn-gifts',
    component: () => import('./views/Index'),
    name: 'earn-gift',
    redirect: { name: 'earn-gift-categories' },
    children: [
      {
        path: 'categories',
        name: 'earn-gift-categories',
        component: () => import('./views/CategoryList'),
        meta: {
          resource: 'goga-earn',
        },
      },
      {
        path: 'categories/:category_id',
        name: 'earn-gift-category',
        component: () => import('./views/CategoryDetail'),
        meta: {
          resource: 'goga-earn',
        },
      },
    ],
  },
]
