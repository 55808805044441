export default [
  {
    path: '/product',
    component: () => import('./views/Index'),
    name: 'product',
    redirect: { name: 'product-list' },
    children: [
      {
        path: '',
        name: 'product-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'content',
        },
      }
    ],
  },
]
