<template>
  <div>
    <b-card class="mb-0">
      <strong class="h6">Text voucher</strong>
      <b-form-input
        v-model="componentData.text_voucher"
        class="mb-1"
        name="text_voucher"
        placeholder="Nhận voucher ưu đãi"
      />
      <strong class="h6">Subtext</strong>
      <b-form-input
        v-model="componentData.subtext"
        class="mb-1"
        name="subtext"
        placeholder="Giảm tới"
      />
      <strong class="h6">Discount voucher</strong>
      <b-form-input
        v-model="componentData.discount_voucher"
        class="mb-1"
        name="discount_voucher"
        placeholder="100.000đ"
      />
    </b-card>
  </div>
</template>

<script>

export default {
  name: 'DiscountOption2Field',
  props: {
    componentData: { type: Object, required: true },
    keyComponent: { type: [String, Number], default: 0 }
  },
  data() {
    return {
    }
  },
}
</script>
