<template>
  <div>
    <b-modal
      id="tab"
      ok-only
      ok-title="Save"
      centered
      title="Audio"
      v-model="isOpen"
      @ok="save"
    >
      <b-form-group label="Name" label-for="name">
        <b-form-input
          id="data_name"
          name="data_name"
          placeholder="Name"
          v-model="data.name"
        />
      </b-form-group>
      <b-form-group label="Audio URL" label-for="audio_src">
        <b-form-input
          id="audio_src"
          name="audio_src"
          placeholder="URL"
          v-model="audio.value"
        />
      </b-form-group>

      <b-form-group label="Audio Title" label-for="audio_title">
        <b-form-input
          id="audio_title"
          name="audio_title"
          placeholder="Audio Title"
          v-model="meta.title"
        />
      </b-form-group>
      <b-form-group label="Spelling" label-for="spelling">
        <b-form-input
          id="spelling"
          name="spelling"
          placeholder="Spelling"
          v-model="data.spelling"
        />
      </b-form-group>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "ModalAudio",
  data() {
    return {
      isOpen: false,
      data: { type: Object },
      audio: { type: Object },
      meta: { type: Object },
      indexTab: null,
      indexAudio: null,
    };
  },
  methods: {
    openModal(indexTab, indexAudio = null, infoAudio = null) {
      this.indexTab = indexTab;
      if (indexAudio != null && infoAudio) {
        (this.indexAudio = indexAudio), (this.meta = infoAudio?.audio?.meta);
        this.audio = infoAudio?.audio;
        this.data = infoAudio;
      }
      this.isOpen = true;
    },
    save() {
      this.audio.meta = { ...this.meta, captions: [] };
      this.data.audio = this.audio;
      if (this.indexAudio == null) {
        this.$emit("createAudio", this.data, this.indexTab);
      }
      this.data = {};
      this.audio = {};
      this.meta = {};
      this.indexAudio = null;
    },
  },
};
</script>
