export default [{
    path: '/admin-user',
    component: () =>
        import ('./views/Index'),
    name: 'admin-user',
    redirect: { name: 'admin-user-list' },
    children: [{
        path: '',
        name: 'admin-user-list',
        component: () =>
            import ('./views/List'),
        meta: {
          resource: 'management',
        },
    }, ],
}, ]
