<template>
  <div class="d-inline-block">
    <b-button variant="flat-info" class="btn-icon rounded-circle" size="sm"
      @click="isModalOpen = true"
    >
      <feather-icon icon="AlertCircleIcon" />
    </b-button>
    <b-modal title="Tips" v-model="isModalOpen" hide-footer centered hide-header-close>
      <tooltip-content :content-key="contentKey" />
    </b-modal>
  </div>
</template>

<script>
import TooltipContent from './TooltipContent.vue'

export default {
  name: 'TooltipButton',
  components: {
    TooltipContent,
  },
  props: {
    contentKey: { type: String, required: true },
  },
  data() {
    return {
      isModalOpen: false,
      content: null,
    }
  },
}
</script>
