<template>
  <div>
    <!-- <b-form-group label="Language" label-for="v-lang"> -->
    <!-- <b-form-select v-model="selected_lang" :options="languages" /> -->
    <multi-select :multiple="false" :id="'language_select' + Math.random()"
      :options="languages"
      :allow_empty="false"
      :disabled="disabled"
      v-model="selected_lang"
    />
    <!-- </b-form-group> -->
  </div>
</template>

<script>
export default {
  name: "LanguageSelect",
  props: {
    language: { type: String, default: "vi" },
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      languages: [],
      selected_lang: "vi",
    };
  },
  computed: {
    authLanguage() {
      return this.$store.getters["auth/language"];
    },
  },
  watch: {
    selected_lang: function () {
      this.$emit("selectedLanguage", this.selected_lang);
    },
    authLanguage: function (newValue) {
      this.selected_lang = newValue;
    },
  },
  created() {
    this.formatLanguage();
  },
  methods: {
    formatLanguage() {
      if (this.selected_lang != this.language) {
        this.selected_lang = this.language;
      }
      this.languages = this.$store.state.language.languages.map((item) => {
        return {
          text: item.code,
          value: item.code,
          img: item.flag,
        };
      });
    },
  },
};
</script>
