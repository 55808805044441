<template>
  <div>
    <b-card>
      <div v-for="(id) in componentData.texts.length" :key="id">
        <b-row class="align-items-center mb-1">
          <b-col cols="11">
            <strong class="h6">Line {{id}}</strong>
            <b-form-input
              v-model="componentData.texts[id - 1]"
              name="heading_text"
              placeholder="Enter content here"
            />
          </b-col>
          <b-col cols="1">
            <b-button
              @click="confirmDelete(id - 1)"
              variant="flat-danger"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div class="text-center mt-2">
        <b-button @click="addRow()" variant="flat-success" class="btn-primary" size="sm">+ Add line</b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'ListTextField',
  props: {
    componentData: { type: Object, required: true },
    keyComponent: { type: [String, Number], default: 0 }
  },
  data() {
    return {
    }
  },
  methods: {
    addRow() {
      this.componentData.texts.push('')
    },

    confirmDelete(index) {
      this.$bvModal
        .msgBoxConfirm("Are you sure ?", {
          title: "Please Confirm",
          size: "md",
          okVariant: "danger",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.deleteRow(index);
          }
        });
    },

    deleteRow(index) {
      this.componentData.texts.splice(index, 1)
    }
  }
}
</script>
