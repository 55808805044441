<template>
  <div>
    <b-modal
      id="tab"
      ok-only
      ok-title="Save"
      centered
      title="Tab"
      v-model="isOpen"
      @ok="save"
    >
        <b-form-group
            label="Name"
            label-for="h-name"
            label-cols-md="4"
        >
          <b-form-input
            id="h-name"
            type="text"
            v-model="tabName"
            placeholder="Name"
          />
        </b-form-group>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'ModalTab',
  data() {
    return {
      isOpen: false,
      tabName: null,
    }
  },
  methods: {
    openModal() {
      this.isOpen = true
    },
    save() {
      this.$emit('createTab', this.tabName)
      this.tabName = null
      this.isOpen = false
    },
  },
}
</script>
