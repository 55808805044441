<template>
  <b-button variant="success" :size="size" class="btn-icon"
    @click="$emit('update')"
  >
    <slot>
      <b-spinner v-if="isLoading" small />
      <feather-icon v-else icon="CheckIcon" />
    </slot>
  </b-button>
</template>

<script>
export default {
  name: 'UpdateButton',
  props: {
    isLoading: { type: Boolean, default: false },
    size: { type: String, default: 'sm' },
  },
}
</script>
