<template>
  <div>
    <b-card>
      <b-col cols="12">
        <b-form-group label="Language" label-for="language">
          <language-select
            :language="language"
            @selectedLanguage="selectedLanguage"
          />
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-form-group>
          <quill-editor
            class="editor"
            ref="myTextEditor"
            v-model="translation.value"
            :options="editorOption"
          />
        </b-form-group>
      </b-col>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import hljs from "highlight.js";

export default {
  components: {
    quillEditor,
  },
  props: {
    componentData: { type: Object, required: true },
  },
  data() {
    return {
      language: "vi",
      translation: {
        value: null,
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ color: ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466"] }],
            [{ align: [] }],
            ["clean"],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },
    };
  },
  created() {
    this.setLanguage();
    this.bindSelectedTranslation();
  },
  methods: {
    bindSelectedTranslation() {
      let existedTranslation = this.componentData.translations.find(
        (item) => item.language == this.language
      );
      if (!existedTranslation) {
        existedTranslation = { language: this.language, value: "" };
        this.componentData.translations.push(existedTranslation);
      }
      this.translation = existedTranslation;
    },
    selectedLanguage(language) {
      this.language = language;
      this.bindSelectedTranslation();
    },
    setLanguage() {
      this.language = this.$store.getters["auth/language"];
    },
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 0 !important;
}
</style>
