<template>
  <b-dropdown :disabled="isLoading" size="sm"
    variant="flat-secondary"
    toggle-class="text-decoration-none"
    no-caret
  >
    <template v-slot:button-content>
      <b-spinner v-if="isLoading" small />
      <feather-icon v-else
        icon="MoreVerticalIcon"
        class="text-body align-middle"
      />
    </template>

    <b-dropdown-item v-for="(action) in actions" :key="action.text || action.icon" @click.stop="function() { action.handler({ item, index }, $event) }">
      <feather-icon v-if="action.icon" :icon="action.icon" class="mr-50" />
      <span>{{ action.text }}</span>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { delay } from '@core/utils/index'

export default {
  name: 'more-actions',
  props: {
    actions: { type: Array, default: () => [{ text: 'Edit', icon: 'Edit2Icon', async handler() { await delay(2000); console.log('edit!'); } }] },
    isLoading: { type: Boolean, default: false },
    item: { type: Object, default: () => {} },
    index: { type: Number, default: 1 },
  },
};
</script>
