export default [
  {
    path: '/pronun',
    component: () => import('./views/Index'),
    name: 'pronun',
    redirect: { name: 'pronun-lesson-list' },
    children: [
      {
        path: 'lessons',
        name: 'pronun-lesson-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'content',
        },
      },
      {
        path: 'lessons/:lesson_id',
        name: 'pronun-lesson-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'content',
        },
      },
    ],
  },
]
