export default [
  {
    path: '/nft-chipset-imgs',
    component: () => import('./views/Index'),
    redirect: { name: 'nft-chipset-imgs' },
    children: [
      {
        path: '',
        name: 'nft-chipset-imgs',
        component: () => import('./views/List'),
        meta: {
          resource: 'goga-earn',
        },
      },
    ],
  },
]
