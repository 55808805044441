import { getUserData } from '@/libs/cookie'

export default {
    namespaced: true,
    state: {
        language: ''
    },
    getters: {
        userData() {
            return getUserData()
        },
        language(state) {
            return state.language
        }
    },
    mutations: {
        GET_AUTH_LANGUAGE(state, language) {
            state.language = language
        }
    },
    actions: {
        setAuthLanguage({ commit }) {
            let user = getUserData();
            commit('GET_AUTH_LANGUAGE', user.language)
        }
    },

}