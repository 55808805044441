export default [
  {
    path: '/ai-positions',
    component: () => import('./views/Index'),
    name: 'ai_position',
    redirect: { name: 'ai_position-list' },
    children: [
      // {
      //   path: '',
      //   name: 'ai_position-list',
      //   component: () => import('./views/List'),
      //   meta: {
      //     resource: 'content',
      //   },
      // },
      {
        path: ':id',
        name: 'ai_position-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'content',
        },
      },
    ],
  },
]
