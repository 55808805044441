export default {
    namespaced: true,
    state: {
        arenaStages: [],
        pronunLessons: [],
        breakingLessons: [],
    },

    getters: {
        arenaStages(state) {
            return state.arenaStages
        },
        pronunLessons(state) {
            return state.pronunLessons
        },
        breakingLessons(state) {
            return state.breakingLessons
        },
    },

    mutations: {
        setArenaStages(state, arenaStages) {
            state.arenaStages = arenaStages
        },
        setPronunLessons(state, pronunLessons) {
            state.pronunLessons = pronunLessons
        },
        setBreakingLessons(state, breakingLessons) {
            state.breakingLessons = breakingLessons
        },
    },
}