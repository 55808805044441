export default [
  {
    path: '/arena-stage',
    component: () => import('./views/Index'),
    name: 'arena-stage',
    redirect: { name: 'arena-stage-list' },
    children: [
      {
        path: 'stages',
        name: 'arena-stage-list',
        component: () => import('./views/List'),
        meta: {
          resource: 'content',
        },
      },
      {
        path: 'stage/:stage_id',
        name: 'arena-stage-detail',
        component: () => import('./views/Detail'),
        meta: {
          resource: 'content',
        },
      },
    ],
  },
]
