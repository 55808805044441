export default {
  image: {
    type: "image",
    value: null,
    meta: {
      url: null,
      alt: null
    }
  },
  name: null,
  level: null,
  study_time: null,
  review_content: null
}