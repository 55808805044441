<template>
  <b-card>
    <b-form-group label="Language" label-for="language">
      <language-select
        :language="selected_lang"
        @selectedLanguage="selectedLanguage"
      />
    </b-form-group>
    <b-form-group>
      <b-form-textarea
        v-if="!isInputField"
        v-model="translation.value"
        id="translation_value"
        placeholder="Translation text"
        rows="2" max-rows="5"
      />
      <b-form-input
        v-else
        v-model="translation.value"
        id="translation_value"
        placeholder="Translation text"
      />
    </b-form-group>
  </b-card>
</template>

<script>
import defaultObject from "./defaultObject";
export default {
  props: {
    componentData: { type: Object, default: defaultObject },
    isInputField: { type: Boolean, default: false },
  },
  data() {
    return {
      selected_lang: this.$store.getters["auth/language"],
      translation: {
        value: null,
      },
    };
  },
  created() {
    this.setLanguage();
    this.bindSelectedTranslation();
  },
  methods: {
    bindSelectedTranslation() {
      if(!this.componentData.translations) { this.componentData.translations = [] }
      let existedTranslation = this.componentData.translations.find(
        (item) => item.language == this.selected_lang
      );
      if (!existedTranslation) {
        existedTranslation = { language: this.selected_lang, value: "" };
        this.componentData.translations.push(existedTranslation);
      }
      this.translation = existedTranslation;
    },
    selectedLanguage(language) {
      this.selected_lang = language;
      this.bindSelectedTranslation();
    },
    setLanguage() {
      this.selected_lang = this.$store.getters["auth/language"];
    },
  },
};
</script>
<style scoped>
.card {
  margin-bottom: 0 !important;
}
</style>
