export default [
    {
      path: '/tutor-levelup-management',
      component: () => import('./views/Index'),
      name: 'tutor-levelup-management',
      redirect: { name: 'tutor-levelup-list' },
      children: [
        {
          path: '',
          name: 'tutor-levelup-list',
          component: () => import('./views/List'),
          meta: {
            resource: 'auth',
          },
        },
      ],
    },
  ]
  